import {ecSendData, trackEvent} from "../../library/analytics";
import {
    hideMenuRight,
    openMenuRight,
    showRightMenu,
    hideMenuRightFull,
    openMenuRightFull
} from "../../library/menu-right";
import {loadRelatedProducts, bestSellersContent, initializeLastViewProducts} from "./related-product";
import {waitingDialog} from "../../library/waiting-dialog";
import {addOverlay, addUpperOverlay} from "../../library/overlay";
import {setCartFunctions} from "./add-to-cart-product-options";
import {addToCartFunctions} from "./add-to-cart-functions";
import {setCookieForADay, getCookie, delCookie} from "../../library/cookies";
import {loadCompositionsOptionModal} from "./loadComposition";
import {ga4TrackSimpleEvent} from "../../website/webpack/modules/analytics";
import CssFilterConverter from "css-filter-converter";

$(document).ready(function(){
    // Cart recover
    recoverCart();

    $(document).on('click', '#hmtCart, #hmtCartSticky, #mobileCart', function() {
        openMenuRightFull();
        showShoppingCart();
        trackEvent('ShoppingCart', 'Open/Close', '');
        ga4TrackSimpleEvent(
            {
                event: 'checkout',
                step: 1,
                method: 'open_cart'
            }
        );
    });

    let updateAJAX;
    $(document).on("click", '.product-cart .scs-remove', function() {
        let item = $(this).closest('.sc-spatula');
        $.post(
            '/ajax/compra/remove-spatula-cart',
            {},
            function(data){
                if(data.removeSpatula){
                    trackEvent('ShoppingCart', 'RemoveSpatula', '', function() {
                        updateShoppingCartCount(data.count);
                        if (data.count == 0) {
                            hideMenuRight();
                        } else {
                            item.remove();
                            updateShippingPrice();
                            sendCartCriteoTag();
                            showShoppingCart();
                            $('#sticker-option-spatula').show();
                        }
                    });
                }
            },
            'json'
        );
    });
    $(document).on("click", '.product-cart .sci-remove', function() {
        let item = $(this).closest('.sc-item'),
            id = item.attr('data-id'),
            ref = item.attr('data-ecRef'),
            productName = item.attr('data-ecProductName'),
            productPrice = item.attr('data-ecItemPrice'),
            productQuantity = item.attr('data-ecQuantity'),
            productColor = item.attr('data-ecVariant'),
            productSize = item.attr('data-ecDimension1'),
            productOrientation = item.attr('data-ecDimension2');
        $.post(
            '/ajax/shopping/remove-item-cart',
            {id: id},
            function(data) {
                if (data.done) {
                    ecSendData(ref, productName, productPrice, productQuantity, productColor, productSize, productOrientation, 'remove', null);
                    trackEvent('ShoppingCart', 'RemoveItem', productName, function() {
                        updateShoppingCartCount(data.count);
                        if (data.count == 0) {
                            hideMenuRight(); // TODO: to be removed
                            hideMenuRightFull();
                        } else {
                            item.remove();
                            updateShoppingCartTotal(data.total, data.estimatedShippingTotal, data.grandTotal);
                            updateShippingPrice();
                            sendCartCriteoTag();
                            showShoppingCart();
                        }
                    });
                }
            },
            'json'
        );
    });
    $(document).on("click", ".product-cart .sci-quantity-minus", function() {
        let btn = $(this),
            quantityInput = btn.siblings('.sci-quantity'),
            units = parseInt(quantityInput.val());
        units -= 1;
        if (units < 1) {
            units = 1;
        }
        if (units === 1) {
            btn.attr('disabled', true);
        }
        quantityInput.val(units).trigger('change');
    });

    $(document).on("click", ".product-cart .sci-quantity-plus", function() {
        let btn = $(this),
            quantityInput = btn.siblings('.sci-quantity'),
            units = parseInt(quantityInput.val());
        units += 1;
        btn.siblings('.sci-quantity-minus').attr('disabled', false);
        quantityInput.val(units).trigger('change');
    });

    $(document).on("change", ".product-cart .sci-quantity", function() {
        if (!$(this).val() || $(this).val() < 1) {
            $(this).val('1');
        }

        let input = $(this),
            item = input.closest('.sc-item'),
            id = item.attr('data-id'),
            ref = item.attr('data-ecRef'),
            productName = item.attr('data-ecProductName'),
            productIntName = item.attr('data-intname'),
            productPrice = item.attr('data-ecItemPrice'),
            productQuantity = item.attr('data-ecQuantity'),
            productColor = item.attr('data-ecVariant'),
            productSize = item.attr('data-ecDimension1'),
            productOrientation = item.attr('data-ecDimension2');

        if (typeof updateAJAX == 'object') {
            updateAJAX.abort();
        }
        updateAJAX = $.post(
            '/ajax/shopping/update-item-quantity',
            {id: id, quantity: input.val(), prevQuantity: input.data('oldval')},
            function(data){
                if (data.done) {
                    let dif = data.dif;
                    let ecAction = 'add';
                    if (dif < 0) {
                        ecAction = 'remove';
                        dif = dif * (-1);
                    }
                    dataLayer.push({ ecommerce: null });
                    dataLayer.push({
                        event: !(ecAction === 'remove') ? "add_to_cart" : "remove_from_cart",
                        ecommerce: {
                            items: [
                                {
                                    item_id: ref,
                                    item_name: productIntName,
                                    currency: data.currency,
                                    price: productPrice,
                                    quantity: dif
                                }
                            ]
                        },
                        dynamicRemarketingData: {
                            value: productPrice,
                            items: [
                                {
                                    id: ref,
                                    google_business_vertical: 'retail'
                                }
                            ]
                        }
                    });
                    ecSendData(ref, productName, productPrice, dif, productColor, productSize, productOrientation, ecAction, null);
                    trackEvent('ShoppingCart', 'ModifyQuantity', productQuantity);
                    item.find('.sci-price-value').html(parseFloat(data.priceTotalArticle).toFixed($('#currencyDecimals').val()));
                    updateShoppingCartCount(data.count);
                   /* data.total = parseFloat(data.total.toFixed($('#currencyDecimals').val()));
                    data.estimatedShippingTotal = parseFloat(data.estimatedShippingTotal.toFixed($('#currencyDecimals').val()));
                    data.grandTotal = parseFloat(data.grandTotal.toFixed($('#currencyDecimals').val()));*/
                    updateShoppingCartTotal(data.total, data.estimatedShippingTotal, data.grandTotal, data.discount);
                    updateShippingPrice();
                    sendCartCriteoTag();

                    input.data('oldval', input.val());
                } else {
                    input.val(data.quantity);
                }
            },
            'json'
        );
    });

    $( window ).resize(function() {
        if ($('#menu-right-container').is(':visible')) {
            setShoppingCartListHeight();
        }
        if ($('#menu-right-full-container').hasClass('open')) {
            setShoppingCartV2ListHeight();
        }
    });

    $('body').on('mousedown', '.product-cart #payWithAmazon', function(event) {
        if (event.which == 3) {return;}
        event.preventDefault();
        trackEvent('AmazonPay', 'GoToCheckout', 'FromShoppingCart');
    });

    $(document).on('click', '.sci-modify', function (e) {
        e.stopPropagation();
        //hideMenuRight();
        modifyProduct($(this));
    });

    $(document).on('click', '.scs-extras-title', function (e) {
        if ($(this).parent().hasClass('open')) {
            $(this).parent().removeClass('open');
        } else {
            $('.scs-extras ').parent().removeClass('open');
            $(this).parent().addClass('open');
        }
    });

    $(document).on('click', '.complement-add-to-cart', function() {
        let button = $(this);
        let productId = button.data('id');
        let width = button.data('width');
        let height = button.data('height');
        let device = button.data('device');
        let price = button.data('price') || 0;
        let composition = button.data('composition');
        let cartFunctions = new addToCartFunctions($('.product-cart'), 'ShoppingCart');

        cartFunctions.addToProductCartAjax(
            {
                productId: productId,
                quantity: 1,
                unitPrice: price,
                options: {
                    width: width,
                    height: height,
                    device: device,
                    composition: composition,
                    price: price
                }
            },
            function(data) {
                cartFunctions.successAddToCart(data, true);
                //showShoppingCart();
            },
            function(data) {
                //TODO ERROR MESSAGE??
            }
        );
    });

    $(document).on('change', '#priority-service', function() {
        let button = $(this);
        let productId = button.val();
        let productCartItemId = button.data('cart-item');
        let name = button.data('name');
        let width = button.data('width');
        let height = button.data('height');
        let device = button.data('device');
        let price = button.data('price') || 0;
        let composition = button.data('composition');
        let loading = document.querySelector('#cb-loading');
        let paypal = document.querySelector('#paypal-button-container');
        if (loading) loading.classList.remove('hidden');
        if (paypal) paypal.classList.add('hidden');
        if (button.prop('checked')) {
            let cartFunctions = new addToCartFunctions($('.product-cart'), 'ShoppingCart');

            cartFunctions.addToProductCartAjax(
                {
                    productId: productId,
                    quantity: 1,
                    isLocked: true,
                    unitPrice: price,
                    options: {
                        width: width,
                        height: height,
                        device: device,
                        composition: composition,
                        price: price
                    }
                },
                function(data) {
                    trackEvent('Cart', 'Priority', 'Add');
                    cartFunctions.successAddToCart(data, true);
                    setTimeout(function () {
                        let loading = document.querySelector('#cb-loading');
                        if (loading) loading.classList.add('hidden');
                        if (paypal) paypal.classList.remove('hidden');
                    }, 3000);
                },
                function(data) {
                    //TODO ERROR MESSAGE??
                },
                false
            );
        } else {
            button.removeProp("checked");
            $.post(
                '/ajax/shopping/remove-item-cart',
                {id: productCartItemId},
                function(data) {
                    if (data.done) {
                        trackEvent('Cart', 'Priority', 'Delete');
                        button.removeProp("checked");
                        updateShoppingCartTotal(data.total, data.estimatedShippingTotal, data.grandTotal);
                        updateShippingPrice();
                        sendCartCriteoTag();
                        showShoppingCart();
                        setTimeout(function () {
                            let loading = document.querySelector('#cb-loading');
                            if (loading) {
                                loading.classList.add('hidden');
                                loading.classList.remove('d-flex');
                            }
                            if (paypal) paypal.classList.remove('hidden');
                        }, 3000);
                    }
                },
                'json'
            );
        }

    });

    $(document).on('click', '.validate-voucher', function(e) {
        e.preventDefault();
        let voucherCode = $('#voucher-code').val();
        if (voucherCode.length) {
            checkVoucher(voucherCode);
        }
    });

    $(document).on('mousedown', '.validate-cart', function() {
        if(!localStorage.getItem('_tv_cookie_choice')){
            setCookieForADay('_tv_in_checkout', 'y');
        }
        trackEvent('ValidateOrder', 'Click', 'FromShoppingCart');
        ga4TrackSimpleEvent(
            {
                event: 'checkout',
                step: 1,
                method: 'start_order'
            }
        );
    });

    $(document).on('click', '#paypal-button-container', function() {
        if(!localStorage.getItem('_tv_cookie_choice')){
            setCookieForADay('_tv_in_checkout', 'y');
        }
        trackEvent('Paypal', 'GoToCheckout', 'FromShoppingCart');
        ga4TrackSimpleEvent(
            {
                event: 'checkout',
                step: 1,
                method: 'paypal'
            }
        );
    });

    $(document).on('click', '.amazon-pay-button-box', function() {
        if(!localStorage.getItem('_tv_cookie_choice')){
            setCookieForADay('_tv_in_checkout', 'y');
        }
        ga4TrackSimpleEvent(
            {
                event: 'checkout',
                step: 1,
                method: 'amazon_pay'
            }
        );
    });

    $(document).on('click', '#menu-right-full-close', function () {
        $(".new-cart .footer").hide();
        ga4TrackSimpleEvent(
            {
                event: 'checkout',
                step: 1,
                method: 'close_cart'
            }
        );
    });

    $(document).on('click', '.new-cart .quantity', function () {
        let isOpened = $(this).hasClass('opened');
        let dropdown = $(this).closest('.quantity-selector').find('.quantity-dropdown');
        if (isOpened) {
            $(this).removeClass('opened').addClass('closed');
            dropdown.removeClass('opened').addClass('closed');
        } else {
            hideAllQuantityDropdowns();
            $(this).removeClass('closed').addClass('opened');
            dropdown.removeClass('closed').addClass('opened');
        }
    });

    $(document).on('click', '.new-cart .quantity-dropdown .item-quantity', function () {
        let prevQuantity = $(this).data('oldval');
        let quantity = $(this).data('quantity');
        let preview = $(this).closest('.quantity-selector').find('.quantity .number');
        $('.new-cart .quantity-dropdown .item-quantity').removeClass('selected');
        $(this).addClass('selected');
        preview.html(quantity);
        hideAllQuantityDropdowns();
        let item = $(this).closest('.sc-item')

        if (typeof updateAJAX == 'object') updateAJAX.abort();

        updateAJAX = $.post(
            '/ajax/shopping/update-item-quantity',
            {id: item.data('id'), quantity: quantity, prevQuantity: prevQuantity},
            function(data){
                if (data.done) {
                    let dif = (data.dif)*1;
                    let ecAction = 'add';
                    if (dif < 0) {
                        ecAction = 'remove';
                        dif = dif * (-1);
                    }
                    dataLayer.push({ ecommerce: null });
                    dataLayer.push({
                        event: !(ecAction === 'remove') ? "add_to_cart" : "remove_from_cart",
                        ecommerce: {
                            items: [
                                {
                                    item_id: item.attr('data-ecRef'),
                                    item_name: item.attr('data-intname'),
                                    currency: data.currency,
                                    price: item.attr('data-ecItemPrice'),
                                    quantity: dif
                                }
                            ]
                        },
                        dynamicRemarketingData: {
                            value: item.attr('data-ecItemPrice'),
                            items: [
                                {
                                    id: item.attr('data-ecRef'),
                                    google_business_vertical: 'retail'
                                }
                            ]
                        }
                    });
                    ecSendData(item.attr('data-ecRef'), item.attr('data-ecProductName'), item.attr('data-ecItemPrice'), dif, item.attr('data-ecVariant'), item.attr('data-ecDimension1'), item.attr('data-ecDimension2'), ecAction, null);
                    trackEvent('ShoppingCart', 'ModifyQuantity', item.attr('data-ecQuantity'));
                }

                showShoppingCart();
            },
            'json'
        );
    });

    $(document).on('click', '.new-cart', function (e) {
        let target = $(e.target);
        let visibleQuantityDropdown = $('.new-cart .quantity-dropdown.opened').length;
        if (visibleQuantityDropdown && !target.hasClass('quantity-selector') && !target.closest('.quantity-selector').length) hideAllQuantityDropdowns();
    });

    $(document).on('click', '.product-popup-options #add-to-cart-custom', function (element) {
        let personalizedText = $('.product-popup-options #personalizedText');

        if (personalizedText) {
            let textarea = personalizedText[0];
            let maxRows = personalizedText.data('maxRows');

            let lines = parseInt(textarea.scrollHeight/parseInt(getComputedStyle(textarea).lineHeight));
            lines = lines < maxRows ? lines : 8;

            personalizedText.attr('rows', lines);
        }
    });

    $(document).on('keyup', '.limit-rows', function () {
        let element = $(this);
        let maxRows = element.data('maxRows');
        let rows = element.attr('rows');
        let numberOfLines = getNumberOfLines(element);
        let textarea = element[0];

        if (!maxRows || (rows >= maxRows && textarea.scrollHeight > textarea.clientHeight)) return;
        if (textarea.scrollHeight > textarea.clientHeight) {
            rows = parseInt(rows) + 1;
            let lines = (rows < maxRows) ? rows : 8;
            element.attr('rows', lines);
        }
        else if (numberOfLines < rows) element.attr('rows', numberOfLines);
    });
});

function getNumberOfLines(element) {
    let textarea = element[0];

    let previous_height = textarea.style.height;

    textarea.style.height = 0;
    let lines = parseInt(textarea.scrollHeight/parseInt(getComputedStyle(textarea).lineHeight) )
    textarea.style.height = previous_height;

    return lines;
}

function modifyProduct($button) {
    let productId = $button.parents('.sc-item').first().data('id');
    let productTitle = $button.parents('.sc-item').first().data('ecproductname');
    let productPrice = $button.parents('.sc-item').first().data('ecitemprice');
    waitingDialog.show();
    $.post(
        '/ajax/shopping/modify-item-cart',
        {id: productId},
        function(data) {
            if (data.done) {
                loadAddToCartProductOptions(data.id, data.productId, data.description, productPrice, data.quantity, data.options);
            }
        }
    );
}

function loadAddToCartProductOptions(itemId, productId, productTitle, productPrice, productQuantity, productOptions) {
    let addToCartOptionsContainer = $('#add-to-cart-options');
    productQuantity = productQuantity || 1;
    productOptions = productOptions || null;
    waitingDialog.show();
    $.post(
        '/ajax/product-list/load-add-to-cart',
        {
            id: productId,
            itemId: itemId,
            title: productTitle,
            price: productPrice,
            quantity: productQuantity,
            options: productOptions,
            modifyProduct: true
        },
        function(data){
            addToCartOptionsContainer.html(data.options);
            setTimeout(function() {
                waitingDialog.hide();
                addUpperOverlay();
                loadCompositionsOptionModal();
                if ($('#add-to-cart-material')){
                    formatMaterialCanvasSize();
                }
                if (!$.trim($('#add-to-cart-other-content').html()).length) {
                    $('#add-to-cart-other').hide();
                }
                addToCartOptionsContainer.find('.slide-up-slider').addClass('open');
                setCartFunctions($('.product-popup-options'), 'ShoppingCart');
            }, 300);
        }
    );
}

function formatMaterialCanvasSize() {
    let materialCanvas = $('#add-to-cart-material');
    let material =  $('#add-to-cart-material input[name="material-canvas"]:checked').val();
    if (material) {
        let sizes = $('.product-popup-options #add-to-cart-size input[name="add-to-cart-size"]');
        sizes.each(function () {
            let size = $(this);
            if (size.data('material-canvas') != material) {
                size.parent().css('display', 'none');
            }
        });
    }
}

export function showShoppingCart() {
    let timestamp = new Date().getTime();
    let renderNewCart = $("#render-new-cart").data('status');
    let menuRight = $("#menu-right-full-container");
    if (!menuRight.hasClass('new-cart') && renderNewCart) menuRight.addClass('new-cart');
    let loader = $(".loader-search");
    loader.show();

    $.post(
        '/ajax/shopping/shopping-cart?t='+timestamp,
        {
            hideComplements: ($("#control2").data("status") == true),
            renderNewCart: renderNewCart
        },
        function(data) {
            if (data.cart) {
                updateShoppingCartCount(data.count);
                showRightMenu(data.title, data.cart, function() {
                    setTimeout(function() {
                        $(".new-cart .footer").show();
                    }, 150);
                });
                setShoppingCartV2ListHeight();
                sendCartCriteoTag();
                if (typeof dataLayer !== 'undefined' && dataLayer) {
                    dataLayer.push({'event': 'optimize.show-shopping-cart'});
                }
                if (data.dataLayer) {
                    eval(data.dataLayer);
                }
                if (typeof bestSellersContent !== 'undefined' && bestSellersContent.length) {
                    $('.cart-body .related-container[data-type="top-sales"]').html(bestSellersContent).initializeSlider(20);
                }
                loadRelatedProducts($('#menu-right-full-container .body'));
                initImagesPreview();

                initializeLastViewProducts();
                // test A/B
                setTimeout(function() {
                    if($("#control2").data("status") == true) {
                        $("div[data-reference='C015']").hide();
                        $("div[data-reference='C016']").hide();
                        $("div[data-reference='C017']").hide();
                        $("div[data-reference='C018']").hide();
                    }
                }, 100);
            }
        }
    );
}

export function openShoppingCartIfNeeded() {
    var opencart = getCookie('_tv_opencart');
    if (opencart.length) {
        showShoppingCart();
        delCookie('_tv_opencart');
    } else {
        $.post(
            '/ajax/shopping/open-shopping-cart-if-needed',
            {},
            function (data) {
                if (data.open) {
                    showShoppingCart();
                }
            }
        );
    }
}

function checkVoucher(voucherCode) {
    let responseVoucher = $('#response-voucher');
    responseVoucher.removeClass("alert-success").removeClass("alert-danger");
    responseVoucher.slideUp('fast');
    $.post(
        '/ajax/shopping/shopping-cart-check-voucher',
        {
            code: voucherCode
        },
        function (data) {
            if (data.done) {
                responseVoucher.removeClass("alert-danger").addClass("alert-success");
            } else {
                responseVoucher.removeClass("alert-success").addClass("alert-danger");
            }
            responseVoucher.html(data.explanation);
            responseVoucher.slideDown('fast');
            if (data.done || data.reload) {
                setTimeout(function() {
                    showShoppingCart();
                }, 10000);
            }
        }
    );
}

function updateShippingPrice() {
    let total = $('#scs-total-value').html();
    let spatulaPrice = $('#scs-price-value');
    if (spatulaPrice.length) {
        total -= parseFloat(spatulaPrice.html());
    }
    if ($('#freeShippingFrom').length) {
        let remainingFreeShipping = ($('#freeShippingFrom').val() - total).toFixed($('#currencyDecimals').val());
        $('#free-shipping-left').html(remainingFreeShipping);
        if (remainingFreeShipping <= 0) {
            if ($('#scss-txt-free').hasClass('hidden')) {
                $('#scss-txt').addClass('hidden');
                $('#scss-txt-free').removeClass('hidden');
            }
        } else {
            if ($('#scss-txt').hasClass('hidden')) {
                $('#scss-txt-free').addClass('hidden');
                $('#scss-txt').removeClass('hidden');
            }
        }
    }
}

function updateShoppingCartTotal(total, shippingTotal, grandTotal, discount) {
    discount = discount || 0;
    let spatulaPrice = $('#scs-price-value');
    if (spatulaPrice.length) {
        total += parseFloat(spatulaPrice.html());
    }

    $('#scs-total-value').html(parseFloat(total).toFixed($('#currencyDecimals').val()));
    if ($('#scs-grandTotal-value').length) {
        $('#scs-shipping-value').html(parseFloat(shippingTotal).toFixed($('#currencyDecimals').val()));
        $('#scs-grandTotal-value').html(parseFloat(grandTotal).toFixed($('#currencyDecimals').val()));
    }
    if (discount) {
        $('#scs-discount-value').html(parseFloat(discount * -1).toFixed($('#currencyDecimals').val()));
    }
}

function updateShoppingCartCount(count) {
    $('#sct-count-number').html(count);
    $('.glyphicon-shopping-cart .count').html(count);
    $('.shopping-cart-count').html(count);
    localStorage.setItem('cartNumberItems', count);
    if (count === 0) $('.new-header .shopping-cart-number-of-items').html('').hide();
    else $('.new-header .shopping-cart-number-of-items').html(count).show();
}

function setShoppingCartListHeight() {
    if ($('.shopping-cart-items-list').length) {
        setTimeout(function() {
            let listSize = ($('.shopping-cart-summary').offset().top - $('.shopping-cart-title').height()) - $(window).scrollTop();
            listSize = listSize - 15;
            $('.shopping-cart-items-list').css('height', listSize + 'px');
        }, 200);
    }
}

function setShoppingCartV2ListHeight() {
    if ($('.scs-items').length) {
        setTimeout(function() {
            let list = $('.product-cart .scs-items');
            if (list.css('overflow') === 'auto') {
                $('.product-cart .scs-footer').css('position', 'absolute');
                let listSize = ($('.scs-checkout-btn').offset().top) - $(window).scrollTop();
                let minSize = $(window).height() ? ($(window).height() / 2) - 50 : 300;
                listSize = listSize < 300 ? minSize : listSize - 15;
                list.css('max-height', listSize + 'px'); //$(window).height() / 2 + 'px');
                $('.product-cart .scs-footer').css('position', 'relative');
            } else {
                list.css('max-height', 'initial');
            }
        }, 200);
    }
}

export function sendCartCriteoTag(){
    if ($('#criteoid').length > 0) {
        let basket = [];
        while (basket.length > 0) {
            basket.pop();
        }
        $('.sc-item').each(function () {
            let qty = $(this).find('.sci-quantity').val();
            basket.push({'id': $(this).data('ecref'), 'price': $(this).data('ecitemprice'), 'quantity': qty});
        });
        window.criteo_q = window.criteo_q || [];
        window.criteo_q.push(
            {event: "setAccount", account: $('#criteoid').val()},
            {event: "setEmail", email: ""},
            {event: "setSiteType", type: deviceType},
            {event: "viewBasket", item: basket});
    }
}

function recoverCart() {
    let currentUrl = window.location.href;
    let paramName = "tvcart";
    paramName = paramName.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + paramName + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(currentUrl);
    if (!results) return null;
    if (!results[2]) return '';
    let hash = decodeURIComponent(results[2].replace(/\+/g, " "));
    $.post(
        '/ajax/compra/recover-cart',
        {'hash': hash},
        function(data) {
            if (data.count && data.count > 0) {
                updateShoppingCartCount(data.count);
                $('#menu-right-container').html(data.cart);
                openMenuRight();
                setShoppingCartListHeight();
                sendCartCriteoTag();
            }
        }
    );
}

export function sendFloodLightEvent() {
    if (window.floodLightConf) {
        gtag('event', 'conversion', {
            'allow_custom_scripts': true,
            'send_to': 'DC-' + window.floodLightConf.accountID + '/' + window.floodLightConf.addToCartTags.groupTag + '/' + window.floodLightConf.addToCartTags.activityTag + '+standard'
        });
    }
}

function hideAllQuantityDropdowns()
{
    $('.new-cart div.quantity.opened').removeClass('opened').addClass('closed');
    $('.new-cart .quantity-selector div.quantity-dropdown.opened').removeClass('opened').addClass('closed');
}

function initImagesPreview()
{
    let itemsImages = document.querySelectorAll('#menu-right-full-container .list-cart__item img');

    if (!itemsImages) return;

    itemsImages.forEach(function(image) {
        let hexCode = image.dataset.colorHexCode;
        let mirrored = image.dataset.mirrored;

        if (hexCode && hexCode.includes('ffffff')) image.parentElement.style.backgroundColor = '#e3e3e3';
        else image.parentElement.style.backgroundColor = '';
        if (hexCode) image.style.filter = CssFilterConverter.hexToFilter(hexCode)['color'];
        else image.style.filter = '';
        if (mirrored === 'true') image.style.transform = 'scaleX(-1)';
        else image.style.transform = '';
    });
}