import {getCookie} from "../../../library/cookies";
import {post} from "../modules/ajax";

export function getAWCFromURL()
{
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('awc')) localStorage.setItem('awc', urlParams.get('awc'));
}

export function sendAWCToCart()
{
    let awc = localStorage.getItem('awc');
    let cartCookie = getCookie('_tv_shopping_cart');

    if (!awc || !cartCookie) return;

    post(
        '/ajax/website/cart/save-awc',
        { awc: awc },
        function () {
            localStorage.removeItem('awc');
        }
    )
}