import {trackEvent, trackLinkEvent} from "../../library/analytics";
import {getDeviceType} from "../../library/device-type";
import {getCookie, setCookie, blockedAllCookies} from "../../library/cookies";
import {setCartFunctions, updateView} from "./add-to-cart-product-options";
import {
    addOverlay,
    addSearchOverlay,
    addSoftOverlay,
    addUpperOverlay, removeOverlay,
    removeSearchOverlay,
    removeSoftOverlay
} from "../../library/overlay";
import {loadRelatedProducts} from "./related-product";
import Swiper from "swiper/js/swiper";
import {getLastSearches, removeSearch} from "../../website/webpack/modules/last-searches";
import {initSearcher} from "../../website/webpack/modules/algolia-searcher";
import {getCurrentLanguage} from "../../website/webpack/modules/locale";
import {isDesktop, isMobile, isIOS} from "../../website/webpack/modules/devices";
import {ga4TrackSimpleEvent, ga4TrackEvent} from "../../website/webpack/modules/analytics";
import {initHeaderOuterMenuSlider} from "../../website/webpack/modules/header-outer-menu-slider";
import {onVisible} from "../../website/scripts/Tools/visible-element-observer";
import {hideElement} from "../../website/webpack/modules/element-visibility";

let searchInput;
const lastSearchesCookie = '_tv_searches';
const userCookie = '_tv_user';
let ajaxSearchSend = null;
let maxSuggestions = isDesktop() ? 12 : 12;
let availableAlgoliaSuggestions = true;

window.onresize = resizeLeftMenu;

$(document).ready(function(){
    loadUserHeader();
    loadLastSearch();
    initHeaderOuterMenuSlider();

    onVisible(document.querySelectorAll("#country-selector img"), (image) => image.src = image.dataset.src);

    availableAlgoliaSuggestions = ($('#autocomplete-method').length && $('#autocomplete-method').val() === 'ALG');

    var swiperMenu = new Swiper('.swiper-menu-container', {
        slidesPerView: 'auto',
        direction: 'horizontal',
        watchOverflow: true,
        spaceBetween: 4,
        freeMode: true,
        navigation: {
            nextEl: '.swiper-menu-button-next',
            prevEl: '.swiper-menu-button-prev',
        }
    });
    var swiperMobileMenu = new Swiper('.swiper-mobile-menu-container', {
        slidesPerView: 'auto',
        direction: 'horizontal',
        watchOverflow: true,
        spaceBetween: 20,
        freeMode: true,
        navigation: {
            nextEl: '.swiper-mobile-menu-button-next',
            prevEl: '.swiper-mobile-menu-button-prev',
        }
    });

    $('#mobileSearchInput').focus(function() {
        if (!$("#header-mobile .search-overlay").length && !$(".new-header").length) {
            addSearchOverlay('mobile');
            trackEvent('Search', 'SearchProduct', 'Type');
            dataLayer.push({'event': 'optimize.algolia'});
        }

        let search = $(this).val();
        loadLastSearches(search, 'mobile');
        if (search.length < 2) {
            clearSuggestions();
        }

        if ($("#mobile-searcher .last-searches").html() !== "" || $("#mobile-searcher .tags").html() !== "" && $('.new-header').length) {
            $("#mobile-searcher .search-suggestions").show();
        } else if ($("#mobile-searcher .last-searches").html() === "" && $("#mobile-searcher .tags").html() === "" && $('.new-header').length) {
            $("#mobile-searcher .search-suggestions").hide();
        }
    });
    $('#desktopSearchInput').focus(function() {
        if (!$("#header-menu .search-overlay").length && !$(".new-header").length) {
            addSearchOverlay('desktop');
            trackEvent('Search', 'SearchProduct', 'Type');
            dataLayer.push({'event': 'optimize.algolia'});
        }
        let search = $(this).val();
        loadLastSearches(search, 'desktop');
        if (search.length < 2) {
            clearSuggestions();
        } else if ($('.search-suggestions .tags').html().length) {
            $('.search-suggestions .tags').show();
        }

        if ($(".searcher .last-searches").html() !== "" || $(".searcher .tags").html() !== "" && $('.new-header').length) {
            $(".searcher .search-suggestions").show();
        } else if ($(".searcher .last-searches").html() === "" && $(".searcher .tags").html() === "" && $('.new-header').length) {
            $(".searcher .search-suggestions").hide();
        }
    });

    $(document).on('click', '.search-overlay', function() {
        removeSearchOverlay();
        hideLastSearches('mobile');
        hideLastSearches('desktop');
        //clearSuggestions();
        $('.search-suggestions .tags').hide();
    });

    $(document).on('click', 'body', function(e) {
        let target = e.target;
        if ($('.new-header').length && !$(target).closest('.searcher').length && !$(target).hasClass('searcher')) {
            $('.new-header .search-suggestions').hide();
        }
    });

    $('#mobileSearchInput').keyup(function() {
        let search = $(this).val();
        loadLastSearches(search, 'mobile');
        if (search.length >= 5 && !availableAlgoliaSuggestions) renderElasticSuggestions($(this));
        if (search.length < 2) {
            clearSuggestions();
        }

        if ($("#mobile-searcher .last-searches").html() === "" && $("#mobile-searcher .tags").html() === "" && $('.new-header').length) {
            $("#mobile-searcher .search-suggestions").hide();
        }
    });
    $('#desktopSearchInput').keyup(function() {
        let search = $(this).val();
        loadLastSearches(search, 'desktop');
        if (search.length >= 5 && !availableAlgoliaSuggestions) renderElasticSuggestions($(this));
        if (search.length < 2) {
            clearSuggestions();
        }
        if ($(".searcher .last-searches").html() === "" && $(".searcher .tags").html() === "" && $('.new-header').length) {
            $(".searcher .search-suggestions").hide();
        }
    });

    $(document).on('click', '#new-design-search .search-suggestion, #mobile-searcher .search-suggestion', function() {
        let search = $(this).data('search');
        let isSavedSearch = $(this).hasClass('user-search');
        let searchType = isSavedSearch ? 'saved-search' : 'suggestion-search';
        $('#mobileSearchInput').val(search);
        goToSearchUrl(search, searchType);

        /*if (isSavedSearch) {
            trackEvent('Search', 'SearchHistory', 'Open');
        } else {
            trackEvent('Search', 'Suggestion', '');
        }*/
    });

    $(document).on('click', '#hmtSearch .search-suggestion', function() {
        let search = $(this).data('search');
        let isSavedSearch = $(this).hasClass('user-search');
        let searchType = isSavedSearch ? 'saved-search' : 'suggestion-search';
        $('#desktopSearchInput').val(search);
        goToSearchUrl(search, searchType);

        /*if (isSavedSearch) {
            trackEvent('Search', 'SearchHistory', 'Open');
        } else {
            trackEvent('Search', 'Suggestion', '');
        }*/
    });

    $(document).on('click', '#new-design-search .remove-suggestion, .new-header #mobile-searcher .remove-suggestion', function(e) {
        e.stopPropagation();
        let search = $(this).parent().data('search');
        removeSearch(search);
        loadLastSearches($('#mobileSearchInput').val(), 'mobile');
        //trackEvent('Search', 'SearchHistory', 'Delete');
        ga4TrackSimpleEvent(
            {
                event: 'searcher',
                method: 'delete-saved-searches'
            }
        );
        $('.new-header #mobileSearchInput').focus();
    });

    $(document).on('click', '#hmtSearch .remove-suggestion', function(e) {
        e.stopPropagation();
        let search = $(this).parent().data('search');
        removeSearch(search);
        loadLastSearches($('#desktopSearchInput').val(), 'desktop');
        //trackEvent('Search', 'SearchHistory', 'Delete');
        ga4TrackSimpleEvent(
            {
                event: 'searcher',
                method: 'delete-saved-searches'
            }
        );
        $('.new-header #desktopSearchInput').focus();
    });

    //Get info of the locale website only.
    $.post(
        '/ajax/header/country-selector',
        {},
        function(data){
            $('#hmtLocales').html(data.countrySelector);
            $('#hmtLocalesMobile').html(data.countrySelectorMobile);
            $('#hmtLocalesFooter').html(data.countrySelectorFooter);
        }
    );
    $('.header-search-form').submit(function(){
        let search = $(this).find('input[name="inputHeaderSearch"]').val();
        if (!search) {
            return false;
        }
        trackEvent('Header', 'Search', search);

        return true;
    });

    $(document).on('click','.df-mobile .df-mobile__searchbox button[data-role="close"]', function(){
        $('.header-search-form-mobile').slideUp();
        $('.search-button').fadeIn();
    });

    $(document).on('click','.df-mobile .df-mobile__action-button[data-role="close"]', function(){
        $('.header-search-form-mobile').slideUp();
        $('.search-button').fadeIn();
    });

    $('.header-search-form-mobile').submit(function(){
        let search = $(this).find('input[name="inputHeaderSearch"]').val();
        if (!search) {
            return false;
        }
        trackEvent('Header', 'Search', search);

        return true;
    });
    $('#hmtSearch span.addon-icon').click(function(){
        $(this).closest('form').submit();
    });
    $('#mobileSearch span.addon-icon').click(function(){
        //hideMobileSearch();
    });
    // $('.header-search-form-mobile #mobileSearch  .input-box').focusout(function(){
    //     $('.header-search-form-mobile').slideUp();
    //     $('.search-button').fadeIn();
    // });
    $('#mobileMenu span').click(function() {
        // hideMobileSearch();
        $('#header-menu').addClass('header-menu-open');
        $('#mobileMenu').delay(400).hide();
        $('#header-menu-close').delay(400).show();

    });

    // Enable new Mobile searcher
    $(".header-search-old").remove();
    $('#header-mobile .search-button, #header-mobile .close-search').click(function () {
        if ($('#mobileSearchInput').is(':visible')) {
            hideMobileSearch();
            $('.header-search-form-mobile').slideUp();
            loadDefaultInformation();
            $('.close-search').fadeOut();
        } else {
            $('#new-design-search .header-search-form-mobile').slideDown();
            openSearchContainer(false);
            $(".remove-search-text, .message-search-empty, .message-error-search").hide();
            $('.close-search').fadeIn();
            $(".executeSearchButton").show();
        }
    });
    $('.executeSearchButton').click(function () {
        if (!searchInput.length) searchInput = isDesktop() ? $('#desktopSearchInput') : $('#mobileSearchInput');
        if (searchInput && searchInput.val() !== "") {
            goToSearchUrl(searchInput.val(), null);
            if ($("#header-menu-mobile .mobile-menu-left").hasClass('open')) trackEvent('Menu', 'Opened', 'Search');
        }
    });
    $('#new-design-search').click(function(e) {
        if (e.target.id == 'mobileSearchInput' || $(e.target).hasClass('executeSearchButton') || $(e.target).hasClass('remove-search-text')) {
            return;
        }
        if ($("#new-design-search .search-input").val() == '') {
            // hideMobileSearch();
            // $('.header-search-form-mobile').slideUp();
            // loadDefaultInformation();
            // $('.close-search').fadeOut();
        }
    })
    // But use the old one for Desktop
    //$('.header-search-new').remove();
    var preventScroll = true;
    $('.header-search-form, #header-menu #header-menu-bottom .sticky-search-icon').on('click', function() {
        if (!preventScroll) {
            $("html, body").animate({
                scrollTop: 0
            }, 500, function () {
                $('#desktopSearchInput').focus();
            });
        }
        preventScroll = false;
    });

    /*
    SEARCHER TEST A/B CODE
     */
    /*
    setTimeout(function () {
        if ($('#testSearcher').data('status') == true) {
            if ($(".new-searcher-desktop").length) {
                $(".old-searcher-desktop").hide();
                $(".new-searcher-desktop").show();
                $(".header-cart").removeClass('col-md-4').addClass('col-md-2');
                $(".header-logo").removeClass('col-md-4').addClass('col-md-3');
                let productTypeSelected = $(".new-searcher-desktop #currentProductTypeNewSearcher").val();
                productTypeSelected = productTypeSelected ? productTypeSelected.replace(/\s/g, '') : null;
                setTimeout(function () {
                    $(`.new-searcher-desktop .dropdown-option[data-product-type="${productTypeSelected}"]`).click();
                    if ($('.new-searcher-desktop .search-input').val() != "") {
                        searchInput = $(".new-searcher-desktop .search-input");
                        // Disabled Search Engine => executeSearch(null, false);
                    }
                }, 100);
            }
        } else {
            if ($('.old-searcher-desktop').length) {
                $(".new-searcher-desktop").hide();
                $(".old-searcher-desktop").show();
                setTimeout(function () {
                    loadSelectedSearchType();
                }, 200);
            }
        }
    }, 100);
    */
    setTimeout(function () {
        let productTypeSelected = $(".new-searcher-desktop #currentProductTypeNewSearcher").val();
        productTypeSelected = productTypeSelected ? productTypeSelected.replace(/\s/g, '') : null;
        setTimeout(function () {
            selectProductType($(`.new-searcher-desktop .dropdown-option[data-product-type="${productTypeSelected}"]`).first());
            if ($('.new-searcher-desktop .search-input').val() != "") {
                searchInput = $(".new-searcher-desktop .search-input");
                // Disabled Search Engine => executeSearch(null, false);
            }
        }, 100);
    }, 100);

    /*
    setTimeout(function () {
        if ($('#testSearcher').data('status') == true) {
            $('#header-mobile .search-button').click(function () {
                $('.header-search-form-mobile').slideDown();
                openSeachContainer(false);
                $(".remove-search-text, .message-search-empty, .message-error-search").hide();
                $(".remove-search-text").hide();
                $('.search-button').fadeOut();
                $(".executeSearchButton").show();
            });
            $('.executeSearchButton').click(function () {
                if (!$("#new-design-search .search-input").val()) {
                    hideMobileSearch();
                    $('.header-search-form-mobile').slideUp();
                    loadDefaultInformation();
                } else if (searchInput && searchInput.val() != "") {
                    goToSearchUrl(searchInput.val());
                }
            });
            $('.search-dropdown').remove();
            $('.header-search-old').remove();

            $(document).keyup(function(event) {
                if (event.which === 27 && $('body').hasClass('soft-overlay-added')) {
                    $('body .soft-overlay').click(); // esc
                    event.preventDefault();
                    event.stopPropagation();
                }
            });

            $(document).on('click', '#desktopSearchInput', function (event) {
                event.preventDefault();
                event.stopPropagation();
                openSeachContainer(true);
            });
            $('#desktopSearchInput').attr('readonly', 'readonly');
            //$('.search-dropdown').hiden();
            $('#header-menu #header-menu-bottom .sticky-search-icon').on('click', function(event) {
                event.preventDefault();
                event.stopPropagation();
                $("html, body").animate({
                    scrollTop: 0
                }, 10, function() {
                    openSeachContainer(true);
                });
            });
        } else {
            $('#header-mobile .search-button').click(function(){
                $('.header-search-form-mobile').slideDown();
                $('.header-search-form-mobile .input-box').focus();
                $('.search-button').fadeOut();
                $(".remove-search-text").hide();
                $(".executeSearchButton").show();
            });
            $('.header-search-new').remove();
            $('.header-search-form, #header-menu #header-menu-bottom .sticky-search-icon').on('click', function() {
                $("html, body").animate({
                    scrollTop: 0
                }, 500, function() {
                    $('#desktopSearchInput').focus();
                });
            });

            loadSelectedSearchType();
        }
    }, 200);
    */

    $(document).on('click', '#header-menu-close', function(){
        $('#header-menu').removeClass('header-menu-open');
        $('#header-menu-close').delay(400).hide();
        $('#mobileMenu').delay(400).show();
    });
    $(document).on('click', '#header-mobile #hmtLogin ', function() {
        let url = $(this).find('input[name="hmtUserUrl"]').val();
        window.location.replace(url);
    });
    $(document).on('click', '#header-menu #hmtLogin', function(e) {
        if (getCookie('_tv_cookie_choice') === "") {
            blockedAllCookies();
        }else{
            let userDropDown = $('#userDropDown');
            trackEvent('Loyalty', 'User', 'open/close');
            if (userDropDown.is(':hidden')) {
                showUserMenu();
                $(document).on('click.header.users', function(e) {
                    if(!userDropDown.is(e.target) && userDropDown.has(e.target).length === 0) {
                        userDropDown.slideUp('fast');
                        $(document).off('.header.users');
                        trackEvent('Loyalty', 'User', 'open/close');
                    }
                });
                ga4TrackSimpleEvent(
                    {
                        event: 'header_user',
                        method: 'open'
                    }
                );
            } else if (!userDropDown.is(e.target) && !userDropDown.has(e.target).length) {
                userDropDown.slideUp('fast');
                ga4TrackSimpleEvent(
                    {
                        event: 'header_user',
                        method: 'close'
                    }
                );
            }
        }
    });
    //Country dropdown
    $(document).on('click', '#hmtCountry', function(){
        let countrySelect = $(this).find('#hmtCountrySelector');
        //Load the subViews
        loadWebSites();
        trackEvent('Loyalty', 'CountrySelector', 'open/close');
        $(document).on('click.header.country', function(e){
            if (!countrySelect.is(e.target) && countrySelect.has(e.target).length === 0) {
                countrySelect.slideUp('fast');
                $(document).off('.header.country');
                trackEvent('Loyalty', 'CountrySelector', 'open/close');
            }
        });
    });
    //language DropDown
    $(document).on('click', '#hmtLanguage', function(){
        let languageSelect = $(this).find('#hmtLanguageSelector');
        loadLanguages();
        trackEvent('Header', 'LanguageSelector', 'open/close');
        $(document).on('click.header.language', function(e){
            if (!languageSelect.is(e.target) && languageSelect.has(e.target).length === 0) {
                languageSelect.slideUp('fast');
                $(document).off('.header.language');
                trackEvent('Header', 'LanguageSelector', 'open/close');
            }
        });
    });

    //Country dropdown Mobile
    $(document).on('click', '#hmtCountryMobile', function(){
        let countrySelect = $(this).find('#hmtCountrySelectorMobile');
        //Load the subViews
        loadWebSitesMobile();
        trackEvent('Loyalty', 'CountrySelector', 'open/close');
        $(document).on('click.header.country', function(e){
            if (!countrySelect.is(e.target) && countrySelect.has(e.target).length === 0) {
                countrySelect.slideUp('fast');
                $(document).off('.header.country');
                trackEvent('Loyalty', 'CountrySelector', 'open/close');
            }
        });
    });
    //language DropDown Mobile
    $(document).on('click', '#hmtLanguageMobile', function(){
        let languageSelect = $(this).find('#hmtLanguageSelectorMobile');
        loadLanguagesMobile();
        trackEvent('Header', 'LanguageSelector', 'open/close');
        $(document).on('click.header.language', function(e){
            if (!languageSelect.is(e.target) && languageSelect.has(e.target).length === 0) {
                languageSelect.slideUp('fast');
                $(document).off('.header.language');
                trackEvent('Header', 'LanguageSelector', 'open/close');
            }
        });
    });

    //Country dropdown Footer
    $(document).on('click', '#hmtCountryFooter', function(){
        let countrySelect = $(this).find('#hmtCountrySelectorFooter');
        //Load the subViews
        loadWebSitesFooter();
        trackEvent('Loyalty', 'CountrySelector', 'open/close');
        $(document).on('click.header.country', function(e){
            if (!countrySelect.is(e.target) && countrySelect.has(e.target).length === 0) {
                countrySelect.slideUp('fast');
                $(document).off('.header.country');
                trackEvent('Loyalty', 'CountrySelector', 'open/close');
            }
        });
    });
    //language DropDown Footer
    $(document).on('click', '#hmtLanguageFooter', function(){
        let languageSelect = $(this).find('#hmtLanguageSelectorFooter');
        loadLanguagesFooter();
        trackEvent('Header', 'LanguageSelector', 'open/close');
        $(document).on('click.header.language', function(e){
            if (!languageSelect.is(e.target) && languageSelect.has(e.target).length === 0) {
                languageSelect.slideUp('fast');
                $(document).off('.header.language');
                trackEvent('Header', 'LanguageSelector', 'open/close');
            }
        });
    });

    $(document).on('mousedown', '.locale-select', function(event) {
        if (event.which == 3) {return;}
        event.preventDefault();
        event.stopPropagation();
        let link = $(this).find('a');
        trackLinkEvent('Header', 'CountrySelector', link.attr('title'), link, event);
        trackEvent('Loyalty', 'CountrySelector', 'Country');
    });
    $(document).on('click', '#hmtReviews', function() {
        let reviewsDropDown = $('#reviewsDropDown');
        trackEvent('Loyalty', 'Reviews', 'open/close');
        if (reviewsDropDown.is(':hidden')) {
            showLastReviews();
            $(document).on('click.header.reviews', function(e) {
                if(!reviewsDropDown.is(e.target) && reviewsDropDown.has(e.target).length === 0) {
                    reviewsDropDown.slideUp('fast');
                    $(document).off('.header.reviews');
                    trackEvent('Loyalty', 'Reviews', 'open/close');
                }
            });
        } else {
            reviewsDropDown.slideUp('fast');
        }
    });
    $(document).on('click', '.hmb-btn', function() {
        let linksBtn = $(this);
        let linksType = linksBtn.find('input[name="hmbType"]').val();
        let linksTypeName = linksBtn.find('input[name="hmbProductTypeName"]').val();
        let linksTypeUrl = linksBtn.find('input[name="hmbProductTypeUrl"]').val();
        let container = isDesktop() ? $('#header-menu') : $("#header-mobile");
        let linksContainer = container.find('.hmb-links-' + linksType);
        let forceRedirect = linksBtn.hasClass('force-redirect');
        trackEvent('Header', linksType, 'open/close');
        ga4TrackSimpleEvent({
            'event': 'header',
            'link_id': linksType,
            'link_text': linksTypeName,
            'link_url': linksTypeUrl
        });
        resizeCategoriesMobile();

        if (forceRedirect) {
            window.location.href = linksTypeUrl;
            return;
        }

        if (linksContainer.is(':hidden')) {
            if (!linksBtn.parent('#header-menu-mobile').length) {
                linksContainer.find('img.lazy-load-img').unveil().removeClass('lazy-load-img');
            }
            if (linksType.indexOf('pc-') !== -1) {
                let linksProductType = linksBtn.find('input[name="hmbProductType"]').val();
                container.find('.hmb-links-pc-' + linksProductType).slideDown('fast');
            } else {
                container.find('.hmb-links-' + linksType).slideDown('fast');
            }
            linksBtn.addClass('hmb-btn-open');
            $(document).on('click.header.links.' + linksType, function(e) {
                if(!linksContainer.is(e.target) && linksContainer.has(e.target).length === 0) {
                    linksContainer.slideUp('fast');
                    linksBtn.removeClass('hmb-btn-open');
                    $(document).off('.header.links.' + linksType);
                    trackEvent('Header', linksType, 'open/close');
                }
            });
        } else {
            linksContainer.slideUp('fast');
        }
    });
    $(document).on('mousedown', '.hmb-pers-main', function(event) {
        if (event.which == 3) {return;}
        event.preventDefault();
        let link = $(this).find('a');
        trackLinkEvent('Header', 'Personalized', link.attr('title'), link, event);
    });
    $(document).on('mousedown', '.hmb-links-Personalized .hmb-link', function(event) {
        if (event.which == 3) {return;}
        event.preventDefault();
        let link = $(this);
        trackLinkEvent('Header', 'Personalized', link.attr('title'), link, event);
    });
    $(document).on('mousedown', '.hmb-links-Theme .hmb-link', function(event) {
        if (event.which == 3) {return;}
        event.preventDefault();
        let link = $(this);
        trackLinkEvent('Header', 'Theme', link.attr('title'), link, event);
    });
    $(document).on('mousedown', '.hmb-links-Location .hmb-link', function(event) {
        if (event.which == 3) {return;}
        event.preventDefault();
        let link = $(this);
        trackLinkEvent('Header', 'Location', link.attr('title'), link, event);
    });

    let oldScrollPosition = $(window).scrollTop();
    // Sticky header
    $(window).on("scroll", function () {
        if ($(this).scrollTop() > 100) {
            $("#header-menu, #hmb-links").addClass("sticky");
        } else {
            $("#header-menu, #hmb-links").removeClass("sticky");
        }

        if ($('.new-header').length && isMobile()) {
            let header = $('#headCnt');
            let headerMessages = $('#header-messages');
            let navigationBar = $('header-navigation-mobile');
            let limit = headerMessages.height() + navigationBar.height();

            if ($(this).scrollTop() > limit && !header.hasClass('header-min') && window.scrollY > oldScrollPosition) {
                header.addClass('header-min');
            } else if (window.scrollY < oldScrollPosition) {
                header.removeClass('header-min');
            }
            oldScrollPosition = $(this).scrollTop();
        }
    });

    //
    // Search functionalities
    //
    $('.old-searcher-desktop .search-dropdown i').on('click', function() {
        toggleSearchTypes();
        trackEvent('Search', 'ProductTypes', 'ProductSelector');
    });

    $('.old-searcher-desktop .search-dropdown .dropdown-option').on('click', function() {
        let productType = $(this).attr('data-productType');

        // Remove all selected
        $('.header-search .search-dropdown .dropdown-option').removeClass('selected');

        // Add selected on clicked element
        $('.header-search .search-dropdown .dropdown-option[data-productType="' + productType + '"]').addClass('selected');

        // Change search input productType
        $('.header-search .search-dropdown-types input[name="searchProductType"]').val($(this).attr('data-productType'));

        // Change search input placeholder
        $('.header-search:not(.header-search-new) .search-input:not(.search-input-new)').attr('placeholder', $(this).attr('data-placeholder'));

        // Save selected product type id on a cookie for 6h
        if(!localStorage.getItem('_tv_cookie_choice')){
            setCookie('_tv_search_type', productType, 365);
        }
        // Hide dropdown types
        hideSearchTypes();
    });

    // Search types
    $(document).on('click', 'body', function(event) {
        // Close search dropdown if click out this
        if (!$(event.target).closest('.search-dropdown, .container-results-search, .container-results-search *').length) {
            hideSearchTypes();
        }

        let newSearcherTargetsDisabled = `
        .container-results-search, 
        .container-results-search *, 
        .search-input, 
        #header-menu-middle, 
        .remove-search, 
        .search-dropdown, 
        .search-dropdown *`;

        if (!$(event.target).is(newSearcherTargetsDisabled)) {
            if ($('#header-menu-middle .container-results-search').is(':visible')) {
                event.stopPropagation();
                event.preventDefault();
                $('#header-menu-middle .container-results-search').slideUp();
                removeSoftOverlay();
                $("#header-menu-middle .remove-search-text").hide();
                $("#header-menu-middle .executeSearchButton").show();

                let device = getDeviceType() == 'desktop' ? 'Desktop' : 'Mobile';
                trackEvent('Search', device, 'open/close');
            }
        }
    });

    $(document).on('click', '.header-menu-item-title', function () {
        if (getDeviceType() == 'desktop') {
            return;
        }

        let links = $(this).siblings('.header-menu-item-links');

        if (links.is(':hidden')) {
            $('.header-menu-item-links').slideUp('fast');
            links.slideDown('fast');
        } else {
            links.slideUp('fast');
        }
    });

    $(document).on('click', '.header-collection-link', function () {
        let title = $(this).attr('title');
        trackEvent('Header', title, 'Click');
    });

    $(".ten-quality-mobile").click(function() {
        trackEvent('Loyalty', 'Reviews', 'open/close');
    });

    updateCartNumber();

    //NEW SEARCHER EVENTS

    let searchInputTimeout;
    $(".search-input:not(.search-input-old)").keyup(function(e) {
        searchInput = $(this);
        if (e.which == 13) {
            return;
        }
        $(".number-of-results").closest('.number-results-container').css('visibility', 'hidden');
        if ($(this).val() !== "") {
            searchInput.closest('.inner-addon, .search-form').find(".remove-search-text").show();
            // $('#mobileSearch .executeSearchButton').removeClass('mdi-navigation-close').addClass('mdi-action-search');
        } else {
            // loadDefaultInformation();
            searchInput.closest('.inner-addon, .search-form').find(".remove-search-text").hide();
            // $('#mobileSearch .executeSearchButton').removeClass('mdi-action-search').addClass('mdi-navigation-close');
            // $(".new-searcher-desktop .container-results-search").slideUp();
        }
        // clearTimeout(searchInputTimeout);
        // searchInputTimeout = setTimeout(function(){
        //     if (searchInput.val() != "") {
        //         executeSearch(null);
        //     }
        // }, 500);
    });

    /*let lastSearch = "";
    $("#mobileSearchInput, .search-input-new").focusout(function() {
        if ($(this).val() != "" && $(this).val().length >= 2 && $(this).val() != lastSearch) {
            lastSearch = $(this).val();
            trackEvent('Search', 'Search', $(this).val());
        }
    });*/

    $(document).on('keypress',function(e) {
        if (e.which == 13) {
            if (!searchInput.length) searchInput = isDesktop() ? $('#desktopSearchInput') : $('#mobileSearchInput');
            if (searchInput && searchInput.is(":focus") && searchInput.val() !== "") {
                goToSearchUrl(searchInput.val(), null);
                if ($("#header-menu-mobile .mobile-menu-left").hasClass('open')) trackEvent('Menu', 'Opened', 'Search');
            }
        }
    });

    $(document).on('click', '#container-paginator .pagination-goto-page', function(e) {
        e.preventDefault();
        let page = $(this).text();
        executeSearch(page);
    });

    $(document).on('change', '#container-paginator #pageSelector', function(e) {
        e.preventDefault();
        let page = $(this).val();
        executeSearch(page);
    });

    $(document).on('click', '.product-type-filter', function() {
        let checkbox = $(this).children('input');

        if ($("input[name='category-filter']:checked").length == 0) {
            if (checkbox.is(':checked')) {
                checkbox.prop('checked', false);
            } else {
                checkbox.prop('checked', true);
            }

            $("input[name='product-type-filter']").not(checkbox).prop('checked', false);

            executeSearch(null);
            trackEvent('Search', 'FilterSelected', checkbox.data('name'));
        }
    });

    $(document).on('click', '.show-children-categories', function() {
        let childContainer = $(this).closest('.category-container').find('.category-children-container');

        if (childContainer.is(":hidden")) {
            $(this).removeClass('mdi-content-add').addClass('mdi-content-remove');
            childContainer.show();
        } else {
            $(this).removeClass('mdi-content-remove').addClass('mdi-content-add');
            childContainer.hide();
        }
    });

    $(document).on('click', '.category-filter, .child-category-filter', function() {
        let checkbox = $(this).children('input');
        let isChildren = $(this).hasClass('child-category-filter');
        if (checkbox.is(':checked')) {
            checkbox.prop('checked', false);
        } else {
            checkbox.prop('checked', true);
        }

        if (isChildren) {
            $(this).closest('.category-container').find('.category-filter input').prop('checked', false);
        } else {
            $(this).closest('.category-container').find('.child-category-filter input').prop('checked', false);
        }

        executeSearch(null);
        trackEvent('Search', 'FilterSelected', checkbox.data('name'));
    });

    $(document).on('click', '.trending-search', function() {
        let name = $(this).find('label').text();
        trackEvent('Category Search', 'Tendencias', name);
    });

    $(document).on('click', '.last-search-item', function() {
        let search = $(this).data('search');
        searchInput = $(".new-searcher-desktop .search-input");
        searchInput.val(search);
        executeSearch(null);
        $(".remove-search-text").show();
        $('#mobileSearch .executeSearchButton').removeClass('mdi-navigation-close').addClass('mdi-action-search');
        trackEvent('Category Search', 'Ultimas busquedas', search);
    });

    $(document).on('click', '.remove-search', function(e) {
        e.preventDefault();
        e.stopPropagation();
        let lastSearches = JSON.parse(getCookie(lastSearchesCookie));
        let index = $(this).data('index');
        lastSearches.splice(index, 1);
        setCookie(lastSearchesCookie, JSON.stringify(lastSearches), 10);
        updateLastSearches();
    });

    $(".remove-search-text").click(function(e) {
        $(this).hide();
        $(this).closest('.inner-addon, .search-form').find('.search-input').val('').focus();
        $("#header-menu-middle .executeSearchButton").show();
        $(".executeSearchButton").show();
        $(".mobile-search-open").removeClass("full");
        $('.search-input.search-input-new:visible, .new-header .search-input').focus();
        $(".new-searcher-desktop .container-results-search").slideUp();
        $(".message-error-search").hide();
        $(".message-search-empty").hide();

        //trackEvent('Search', 'SearchProduct', 'Delete');
        ga4TrackSimpleEvent(
            {
                event: 'searcher',
                method: 'delete'
            }
        );
    });

    $(".container-results:not(.desktop-results)").scroll(function() {
        let scrollPercent = parseFloat(100 * $(this).scrollTop() / ($(this).find('.container-products').height() - $(this).height())).toFixed(0);
        if (scrollPercent >= 80 && $('.mobile-search-open').length > 0) {
            addProducts();
        }
    });

    $(".btn-show-filters").click(function() {
        if (!$(this).hasClass('loader-show')) {
            $(".container-filters-buttons").hide();
            $(".number-results-container").css('visibility', 'hidden');
            $('.container-filters').show();
            $('.container-products').slideUp('slow', function () {
                $('.container-filters-results, .exit-filters').show();
            });
            trackEvent('Search', 'ShowFilters');
        }
    });

    $(".exit-filters, .btn-show-results").click(function() {
        hideFilters();
    });

    $(document).on('click', '.panel-dropdown-header', function() {
        if (!$(this).parents('.always-open').length || getDeviceType() !== 'mobile') {
            if ($(this).hasClass('panel-open')) {
                $(this).find('.panel-icon').removeClass('glyphicon-menu-up').addClass('glyphicon-menu-down');
                $(this).removeClass('panel-open');
            } else {
                $(this).find('.panel-icon').removeClass('glyphicon-menu-down').addClass('glyphicon-menu-up');
                $(this).addClass('panel-open');
            }
        }
    });

    $(document).on('click', '.btn-remove-filters, #header-menu-middle .btnClearFilter', function() {
        $("input[name='product-type-filter'], input[name='category-filter']").prop('checked', false);
        executeSearch(null);
        trackEvent('Search', 'ClearFilters', 'click');
    });

    $(".btn-show-sort").click(function() {
        if (!$(this).hasClass('loader-show')) {
            $(".container-filters-buttons").hide();
            $(".number-results-container").css('visibility', 'hidden');
            $('.container-sort').show();
            $('.container-products').slideUp('slow', function() {
                $(".exit-filters").show();
            });
            trackEvent('Search', 'ShowSort');
        }
    });

    $(document).on('click', '.searcher-sort', function() {
        let checkbox = $(this).children('input');

        if (checkbox.is(':checked')) {
            checkbox.prop('checked', false);
        } else {
            checkbox.prop('checked', true);
        }

        $("input[name='searcher-sort']").not(checkbox).prop('checked', false);

        executeSearch(null);
        trackEvent('Search', 'SortSelected', checkbox.val());
    });

    //ANALYTICS EVENTS

    $(document).on('click', '.container-products .list-add-to-cart', function (e) {
        e.preventDefault();
        e.stopPropagation();
        let productId = $(this).data('id');
        let productTitle = $(this).data('title');
        let productPrice = $(this).data('price');
        if(localStorage.getItem('_tv_cookie_choice')){
            blockedAllCookies();
        }else{
            loadAddToCartOptions(productId, productTitle, productPrice);
            trackEvent('Search', 'AddToCartPopUp', 'open');
        }
    });

    $(document).on('click', '.btnHidePopup', function(e){
        e.stopPropagation();
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        $('body').css("overflow","");
        if (isNewSearcherPopUp) {
            trackEvent('Search', 'AddToCartPopUp', 'close');
        }
    });

    $(document).on("click", ".product-popup-options .qty-btn-remove", function() {
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        if (isNewSearcherPopUp) {
            trackEvent('Search', 'ProductQuantity', 'add/remove');
        }
    });

    $(document).on("click", ".product-popup-options .qty-btn-add", function(e) {
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        if (isNewSearcherPopUp) {
            trackEvent('Search', 'ProductQuantity', 'add/remove');
        }
    });

    $(document).on('click', '.product-popup-options .sticker-color-select', function() {
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        if (isNewSearcherPopUp) {
            trackEvent('Search', 'Color', $(this).attr('title'));
        }
    });

    $(document).on('click', '.product-popup-options .material-color-select', function () {
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        if (isNewSearcherPopUp) {
            trackEvent('Search', 'Material Color', $(this).attr('title'));
        }
    });

    $(document).on('change', '.product-popup-options input[name="reversed"]', function () {
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        if (isNewSearcherPopUp) {
            if ($(this).is(':checked')) {
                trackEvent('Search', 'GlassReversed', 'add');
            } else {
                trackEvent('Search', 'GlassReversed', 'remove');
            }
        }
    });

    $(document).on('change', '.product-popup-options #personalizedImage, .product-popup-options .multi-product-options .personalizedImageMultiProduct', function (event) {
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        if (isNewSearcherPopUp) {
            trackEvent('Search', 'Upload', 'ok');
        }
    });

    $(document).on('change', '.product-popup-options #preview-size', function () {
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        if (isNewSearcherPopUp) {
            trackEvent('Search', 'Size', $(this).text());
        }
    });

    $(document).on('change', '.product-popup-options input[name="orientation"]', function () {
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        if (isNewSearcherPopUp) {
            trackEvent('Search', 'Orientation', $(this).val());
        }
    });

    let timeOutPersonalizedText;
    $(document).on('keydown', '.product-popup-options input[name="personalizedText"]', function () {
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        if (isNewSearcherPopUp) {
            clearTimeout(timeOutPersonalizedText);
            timeOutPersonalizedText = setTimeout(function () {
                trackEvent('Search', 'PersonalizedText', '');
            }, 1000);
        }
    });

    $(document).on('change', '.product-popup-options input[name="complement"]', function (event) {
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        let validReferences = [
            'C015',
            'C016',
            'C017',
            'C018'
        ]
        let reference = $(this).data('reference');
        if ($(this).prop('checked') && reference && validReferences.includes(reference) && isNewSearcherPopUp) {
            trackEvent('Search', 'AddToCartCrossSelling', reference);
        } else if (isNewSearcherPopUp) {
            trackEvent('Search', 'Complement', $(this).data('name'));
        }
    });

    $(document).on('change', '.product-popup-options input[name=pack]', function () {
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        if (isNewSearcherPopUp) {
            trackEvent('Search', 'Size', $(this).data('name'));
        }
    });

    $(document).on('mousedown', '.container-products .list-item', function(event){
        if ($(event.target).is('.swiper-button-prev') || $(event.target).parents('.swiper-button-prev').length){return;}
        if ($(event.target).is('.swiper-button-next') || $(event.target).parents('.swiper-button-next').length){return;}
        if ($(event.target).is('.wishlist') || $(event.target).parents('.wishlist').length){return;}
        if ($(event.target).is('.list-add-to-cart') || $(event.target).parents('.list-add-to-cart').length){return;}
        if (event.which == 3) {return;}
        let link = $(this).find('.title a');
        let name = link.attr('title');
        trackLinkEvent('Search', 'ClickProduct', name, link, event);
    });

    $(document).on('click', '.product-popup-options .sticker-options-plain-cr', function (e) {
        e.preventDefault();
        let checkbox = $(this).find('input');
        if (checkbox.prop('checked')) {
            checkbox.prop('checked', false).trigger('change');
        } else {
            checkbox.prop('checked', true).trigger('change');
        }
    });

    $("#btn-clear-last-searches").click(function() {
        setCookie(lastSearchesCookie, '[]', 10);
        updateLastSearches();
    });

    $(window).resize(function() {
        resizeNewSearcherMobile();
    });

    //ONLY MOBILE
    $("#header-mobile .container-results").scroll(function() {
       if ($("#mobileSearchInput").is(":focus")) {
           $("#mobileSearchInput").blur();
       }
       resizeNewSearcherMobile();
    });

    $('.new-searcher-desktop .search-dropdown').on('click', function() {
        toggleSearchTypes(true);
        trackEvent('Search', 'ProductTypes', 'ProductSelector');
    });

    $('.new-searcher-desktop .dropdown-option').click(function (e) {
        e.stopPropagation();
        let productType = $(this).data('productType');
        let originalName = $(this).data('originalName');

        selectProductType($(this));

        // TODO: not track if not visible
        setCookie('_tv_search_type', productType, 365);
        trackEvent('Search', 'ProductTypes', originalName);
        if (searchInput && $('.new-searcher-desktop .search-input').val() != "") {
            // executeSearch(null);
        }
    });

    /* Disabled Desktop Area Below Search Field */
    $(".new-searcher-desktop .search-input").click(function() {
    /*
        let search = $(this).val();
        if (search !== '' && !ajaxSearchSend) {
            $('.new-searcher-desktop .container-results-search').slideDown();
            $(".remove-search-text").show();
        }
    */
    });

    $('.new-searcher-desktop .executeSearchButton').click(function(e) {
        e.stopPropagation();
        let search = $(".new-searcher-desktop .search-input").val();
        if (search !== '') {
            goToSearchUrl(search, null);
        }
    });

    //LEFT MENU MOBILE
    $('#mobileMenu').on('click', function() {
        removeOverlay();
        resizeLeftMenu();
        addOverlay();
        trackEvent('Menu', 'Open');
        $("#header-menu-mobile .mobile-menu-left").addClass('open');
    });

    $('#header-menu-mobile .mobile-menu-left .close-left-menu').click(function () {
        closeLeftMenu();
    });

    $('#header-menu-mobile .mobile-menu-left .searcher .search-input').keyup(function() {
        let search = $(this).val().trim();
        loadLastSearches(search, 'mobile');
        if (search.length >= 5 && !availableAlgoliaSuggestions) renderElasticSuggestions($(this));
        if (search.length < 2) clearSuggestions();
    });

    $('#header-menu-mobile .mobile-menu-left .searcher .search-input').focus(function () {
        let search = $(this).val();
        loadLastSearches(search, 'mobile');
        if (search.length < 2) {
            clearSuggestions();
        }
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .searcher .search-suggestion', function() {
        let search = $(this).data('search');
        $('#header-menu-mobile .mobile-menu-left .searcher .search-input').val(search);
        goToSearchUrl(search, null);
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .searcher .remove-suggestion', function(e) {
        e.stopPropagation();
        let search = $(this).parent().data('search');
        removeSearch(search);
        loadLastSearches($('#header-menu-mobile .mobile-menu-left .searcher .search-input').val(), 'mobile');
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left', function(e) {
        let target = $(e.target);
        if (target.hasClass('searcher') || target.closest('.searcher').length) return;
        hideLastSearches('mobile');
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .open-categories', function() {
        let productType = $(this).data('productType');
        let categories = $(`#header-menu-mobile .mobile-menu-left .categories[data-product-type=${productType}]`);
        categories.show();
        $('#header-menu-mobile .row.middle').scrollTop(0);
        $("#header-menu-mobile .mobile-menu-left .main-menu").slideUp();
        trackEvent('Menu', 'Opened', 'Tool/Product type');
        ga4TrackEvent(this);
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .open-main-menu', function() {
        let categories = $(this).closest('.categories').hide();
        $("#header-menu-mobile .mobile-menu-left .main-menu").slideDown(400, function() {
            categories.hide();
        });
        trackEvent('Menu', 'Opened', 'Home');
        ga4TrackEvent(this);
    });

    $(document).on('click', '.overlay', function() {
        let leftMenu = $("#header-menu-mobile .mobile-menu-left");
        if (leftMenu.hasClass('open')) {
            $('#header-menu').removeClass('header-menu-open');
            $('#header-menu-close').delay(400).hide();
            $('#mobileMenu').delay(400).show();
            removeOverlay();
            leftMenu.removeClass('open');
        }
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .country-selector', function() {
        let selector = $(this).find('.selector');
        let div = selector.find('.content');
        if (div.children().length === 0) {
            $.post(
                '/ajax/left-menu/countries-dropdown',
                {},
                function(data) {
                    div.html(data.countrySelector);
                    selector.slideDown('fast');
                }
            );
        } else selector.slideDown('fast');
        trackEvent('Menu', 'Opened', 'CountrySelector');
        ga4TrackEvent(this);
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .country-selector .close-selector', function(e) {
        e.stopPropagation();
        let selector = $('#header-menu-mobile .mobile-menu-left .country-selector .selector');
        selector.slideUp('fast');
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .reviews', function() {
        trackEvent('Menu', 'Opened', 'Reviews');
        ga4TrackEvent(this);
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .login', function() {
        trackEvent('Menu', 'Opened', 'User');
        ga4TrackEvent(this);
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .home a', function(e) {
        e.preventDefault();
        let isHomepage = $(this).data('isHomepage');
        if (isHomepage) {
            closeLeftMenu();
        } else {
            window.location.href = $(this).attr('href');
        }
        trackEvent('Menu', 'Opened', 'Home');
        ga4TrackEvent(this);
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .personalized-link', function() {
        trackEvent('Menu', 'Opened', 'Tool/Product type');
        ga4TrackEvent(this);
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .category-link', function() {
        trackEvent('Menu', 'Opened', 'Categoría');
        ga4TrackEvent(this);
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .image-claims img', function() {
        trackEvent('Menu', 'Opened', 'frase');
        //ga4TrackEvent(this);
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .open-personalized-blocks', function() {
        $('#header-menu-mobile .mobile-menu-left .personalized-blocks').show();
        $("#header-menu-mobile .mobile-menu-left .main-menu").slideUp();
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .close-personalized-blocks', function() {
        $("#header-menu-mobile .mobile-menu-left .main-menu").slideDown(400, function() {
            $('#header-menu-mobile .mobile-menu-left .personalized-blocks').hide();
        });
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .close-personalized-links, #header-menu-mobile .mobile-menu-left .open-personalized-links', function() {
        let isOpened = $(this).hasClass('close-personalized-links');
        let personalizedLinks = $('#header-menu-mobile .mobile-menu-left .personalized-links');
        if (isOpened) {
            personalizedLinks.slideUp();
            $(this).removeClass('close-personalized-links');
            $(this).addClass('open-personalized-links');
        } else {
            personalizedLinks.slideDown();
            $(this).removeClass('open-personalized-links');
            $(this).addClass('close-personalized-links');
        }
    });

    window.setInterval(
        function () {
            let images = $('.image-claims .image');
            if (images.length > 1) {
                let currentItem = $('.image-claims .image:visible');
                let nextItem = currentItem.next('.image').length ? currentItem.next('.image') : images.first();
                currentItem.fadeOut("300", function () {
                    nextItem.fadeIn("300");
                });
            }
        },
        5000
    );

    $(document).on('click', '.close-categories', function() {
        $(this).closest('.hmb-links-container').slideUp('fast');
        $("#header-mobile .hmb-btn").removeClass('hmb-btn-open');
        $(document).off('.header.links');
    });

    $(document).on('click', '#header-mobile #hmb-links-mobile a', function() {
        trackEvent('Header', $(this).data('productType'), $(this).text());
    });

    $(document).on('click', '#reviewsDropDown .view-all', function() {
        trackEvent('Loyalty', 'Reviews', 'SeeMore');
    });

    $(document).on('submit', '#userDropDown .loginForm', function() {
        trackEvent('Loyalty', 'User', 'StartSession');
    });

    $(document).on('click', '#userDropDown .create-user', function() {
        trackEvent('Loyalty', 'User', 'CreateUser');
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .country-selector a.language', function() {
        trackEvent('Loyalty', 'LanguageSelector', 'open/close');
    });

    $(document).on('click', '#header-menu-mobile .mobile-menu-left .country-selector .locale-select-dropdown .header', function() {
        console.log("MELO");
        let body = $(this).closest('.locale-select-dropdown').find('.body');
        if (!body.is(":visible")) {
            body.slideDown();
        } else {
            body.slideUp();
        }
    });

    $('#header-messages .close-messages').click(function() {
        if (!localStorage) return;
        let date = new Date();
        localStorage.setItem('tvn_header_messages_close', date.getTime());
        $('#header-messages').addClass('closed-messages');
        ga4TrackSimpleEvent(
            {
                event: 'loyalty',
                method: 'close'
            }
        );
    })

    window.addEventListener('native.hidekeyboard', function () {
        let suggestions = $('.new-header .search-form').find('.search-suggestions');
        suggestions.hide();
    });
});

function selectProductType($element) {
    let productTypeId = null;
    if ($element && $element.length) {
        let name = $element.data('name');
        let productType = $element.data('productType');
        let originalName = $element.data('originalName');
        let placeHolder = $element.data('placeholder');
        productTypeId = $element.data('id') ? $element.data('id').toString() : null;
        $('.new-searcher-desktop .dropdown-option').removeClass('selected');
        $(this).addClass('selected');
        $('.new-searcher-desktop #currentProductTypeNewSearcher').val(productType);
        $('.new-searcher-desktop #currentProductTypeNameNewSearcher').val(originalName);
        $('#desktopSearchInput').attr('placeholder', placeHolder);
        $('#mobileSearchInput').attr('placeholder', placeHolder);
        $(".new-searcher-desktop .search-dropdown-product-type-active").html(name);
        hideSearchTypes();
    }
    if (availableAlgoliaSuggestions) initSearchers(productTypeId);
}

function loadSelectedSearchType() {
    let productTypeBySection = $('#currentProductType').val();
    let productTypeByCookie;
    if(!localStorage.getItem('_tv_cookie_choice')) {
        productTypeByCookie = getCookie('_tv_search_type');
    }
    let productType;

    if (productTypeBySection) {
        productType = productTypeBySection;
    } else if (productTypeByCookie) {
        productType = productTypeByCookie;
    }

    if (productType) {
        $('#hmtSearch .search-dropdown .dropdown-option[data-productType="' + productType + '"]').click();
    } else {
        $('#hmtSearch .search-dropdown .dropdown-option').first().click();
    }
}

function toggleSearchTypes(newSearcher = false) {
    let isOpened;
    if (newSearcher) {
        isOpened = !!parseInt($('.new-searcher-desktop .search-dropdown').attr('data-open'));
    } else {
        isOpened = !!parseInt($('.old-searcher-desktop .search-dropdown').attr('data-open'));
    }

    if(isOpened) {
        hideSearchTypes();
    } else {
        showSearchTypes();
    }
}

function hideSearchTypes() {
    $('.new-searcher-desktop .search-dropdown').attr('data-open', "0");
    $('.new-searcher-desktop .search-dropdown i').removeClass('glyphicon glyphicon-triangle-top').addClass('glyphicon glyphicon-triangle-bottom');
    $('.old-searcher-desktop .search-dropdown').attr('data-open', "0");
    $('.old-searcher-desktop .search-dropdown i').removeClass('glyphicon glyphicon-chevron-up').addClass('glyphicon glyphicon-chevron-down');
    $('.header-search .search-dropdown .search-dropdown-types').hide();
}

function showSearchTypes() {
    $('.new-searcher-desktop .search-dropdown').attr('data-open', "1");
    $('.new-searcher-desktop .search-dropdown i').removeClass('glyphicon glyphicon-triangle-bottom').addClass('glyphicon glyphicon-triangle-top');
    $('.old-searcher-desktop .search-dropdown').attr('data-open', "1");
    $('.old-searcher-desktop .search-dropdown i').removeClass('glyphicon glyphicon-chevron-down').addClass('glyphicon glyphicon-chevron-up');

    $('.header-search .search-dropdown .search-dropdown-types').show();
}

function showLastReviews() {
    let reviewsDropDown = $('#reviewsDropDown');
    if (reviewsDropDown.is(':empty')) {
        $.post(
            '/ajax/header/reviews-dropdown',
            {},
            function(data) {
                if (getDeviceType() == 'mobile') {
                    location = data.reviewsUrl;
                } else {
                    reviewsDropDown.html(data.view);
                    reviewsDropDown.slideDown('fast');
                }
            }
        );
    } else {
        reviewsDropDown.slideDown('fast');
    }
}

function loadWebSites() {
    let countryDropDown = $('#hmtCountrySelector');
    if (countryDropDown.children().length == 0) {
        $.post(
            '/ajax/header/country-dropdown',
            {},
            function(data) {
                countryDropDown.html(data.countrySelector);
                overrideWebsiteLinks();
                countryDropDown.slideDown('fast');
            }
        );
    } else countryDropDown.slideDown('fast')
}

function loadLanguages() {
    let languageDropDown = $('#hmtLanguageSelector');
    if (languageDropDown.children().length == 0) {
        $.post(
            '/ajax/header/language-dropdown',
            {},
            function(data) {
                languageDropDown.html(data.languageSelector);
                overrideWebsiteLinks();
                languageDropDown.slideDown('fast');
            }
        )
    }else languageDropDown.slideDown('fast');
}

function overrideWebsiteLinks (){
    let alternates = $('link[rel=alternate]');
    $.each(alternates,function () {
        let hreflang = $(this).attr('hreflang');
        let checkLang = hreflang.replace("-","_");
        let href = $(this).attr('href');
        let link = $('.locale-select a[data-locale='+checkLang+']');
        link.attr("href", href);
    });
}

function loadWebSitesMobile() {
    let countryDropDown = $('#hmtCountrySelectorMobile');
    if (countryDropDown.children().length == 0) {
        $.post(
            '/ajax/header/country-dropdown',
            {},
            function(data) {
                countryDropDown.html(data.countrySelector);
                overrideWebsiteLinks();
                countryDropDown.slideDown('fast');
            }
        );
    } else countryDropDown.slideDown('fast')
}

function loadLanguagesMobile() {
    let languageDropDown = $('#hmtLanguageSelectorMobile');
    if (languageDropDown.children().length == 0) {
        $.post(
            '/ajax/header/language-dropdown',
            {},
            function(data) {
                languageDropDown.html(data.languageSelector);
                overrideWebsiteLinks();
                languageDropDown.slideDown('fast');
            }
        )
    }else languageDropDown.slideDown('fast');
}

function loadWebSitesFooter() {
    let countryDropDown = $('#hmtCountrySelectorFooter');
    if (countryDropDown.children().length == 0) {
        $.post(
            '/ajax/header/country-dropdown',
            {},
            function(data) {
                countryDropDown.html(data.countrySelector);
                countryDropDown.slideDown('fast');
            }
        );
    } else countryDropDown.slideDown('fast')
}

function loadLanguagesFooter() {
    let languageDropDown = $('#hmtLanguageSelectorFooter');
    if (languageDropDown.children().length == 0) {
        $.post(
            '/ajax/header/language-dropdown',
            {},
            function(data) {
                languageDropDown.html(data.languageSelector);
                languageDropDown.slideDown('fast');
            }
        )
    }else languageDropDown.slideDown('fast');
}

function showUserMenu() {
    let userDropDown = $('#userDropDown');
    if (userDropDown.is(':empty')) {
        $.post(
            '/ajax/header/user-dropdown',
            {},
            function(data) {
                if (getDeviceType() == 'mobile') {
                    location = data.usersUrl;
                } else {
                    userDropDown.html(data.view);
                    if (data.logged) {
                        userDropDown.addClass('logged-dropdown');
                    } else {
                        window.facebookSignWithInit();
                        google.accounts.id.renderButton(
                            userDropDown[0].querySelector('.g_id_signin'),
                            { type: "icon", size: "large", theme: "outline" }  // customization attributes
                        );
                    }
                    userDropDown.slideDown('fast');
                }
            }
        );
    } else {
        userDropDown.slideDown('fast');
    }
}

function updateCartNumber()
{
    let cookie = getCookie('_tv_shopping_cart');
    let container = $('.shopping-cart-number-of-items');
    container.hide();
    if (cookie && container.length) {
        let items = localStorage.getItem("cartNumberItems");
        if (items) {
            items = parseInt(items);
            container.html(items);
            container.show();
            if (items === 0) $('.new-header .shopping-cart-number-of-items').html('').hide();
        } else {
            $.post(
                '/ajax/header/update-cart-number',
                {cookie: cookie},
                function(data) {
                    container.html(data.number);
                    localStorage.setItem("cartNumberItems", data.number);
                    container.show();
                    if (data.number === 0) $('.new-header .shopping-cart-number-of-items').html('').hide();
                }
            );
        }
    } else {
        container.html(0);
        container.show();
        $('.new-header .shopping-cart-number-of-items').html('').hide();
    }
}

//NEW SEARCHER FUNCTIONS
let lastSearch = "";

function executeSearch(page, show)
{
    show = (show === undefined) ? true : show;
    $(".message-search-empty, .message-error-search").hide();
    $('#current-page-new-searcher').val(1);
    resizeNewSearcherMobile();
    let text = searchInput.val();
    let currentProductType = $("#currentProductTypeNewSearcher").val();
    let allProducts = $("#url-all-products").val();
    let urlProductType = currentProductType ? currentProductType : allProducts
    let urlAjax = '/ajax/product-list/update';
    let url = addParamUrl('/'+urlProductType+'/search', 'q', text);
    let filters = buildFilters();

    if (page) {
        urlAjax = addParamUrl(urlAjax, 'page', page);
    }

    let data = {
        q: text,
        type: urlProductType,
        url: url,
        newSearcher: true,
        filters,
        sort: $("input[name='searcher-sort']:checked").val()
    }

    showLoaderSearch();
    if (text.length >= 2) {

        if (ajaxSearchSend) {
            ajaxSearchSend.abort();
        }

        ajaxSearchSend = $.ajax({
            type: 'POST',
            url: urlAjax,
            data: data,
            success: function (data) {
                $(".number-of-results").html(data.count);
                $(".number-of-results").closest('.number-results-container').css('visibility', 'visible');
                $(".container-products").html(data.products);
                $(".container-paginator").html(data.paginator);
                $(".container-filters").html(data.filters);
                $('#container-trending-searches .content').html(data.trendingItems);

                if (!$(".container-filters").is(":visible")){
                    hideFilters();
                    $(".container-filters-buttons").show();
                    $(".container-results, .container-products").show();
                } else {
                    $("#header-mobile .number-results-container").css('visibility', 'hidden');
                }

                hideLoaderSearch();

                if ($(".container-products .list-item").length === 0) {
                    if (data.elasticsearchDown) {
                        $(".message-error-search p").text(data.errorMessage || '');
                        $(".message-error-search").slideDown();
                        $(".mobile-search-open").addClass("full");
                    } else {
                        $(".message-search-empty p").text(data.emptySearchMessage || '');
                        $(".message-search-empty").slideDown();
                        $(".mobile-search-open").addClass("full");
                    }
                    loadDefaultInformation();
                } else {
                    $("#search-related-products, #search-related-products-mobile").hide();
                    updateCookieLastSearches(text);
                    if (lastSearch !== text) {
                        lastSearch = text.trim();
                        trackEvent('Search', 'Search', text);
                    }
                    $(".mobile-search-open").addClass("full");
                }

                $("#header-menu-middle .container-filters, #header-menu-middle .container-sort").show();

                if ($("input[name='product-type-filter']:checked").length > 0) {
                    let categories = $('.panel-filter-category .panel-dropdown-body');
                    if (categories.length && categories.html().trim().length) {
                        $(".panel-filter-category").show().find('.panel-dropdown-body').hide();
                        $(".panel-filter-category").find('.panel-icon').removeClass("glyphicon-menu-down")
                            .addClass("glyphicon-menu-up");
                        $(".panel-filter-product-type").find('.panel-dropdown-body').show();
                        $(".panel-filter-product-type").find('.panel-icon').removeClass("glyphicon-menu-up")
                            .addClass("glyphicon-menu-down");
                    }
                }

                updateLastSearches();
                if (show) {
                    openSearchContainer(true);
                }
                ajaxSearchSend = null;
            }
        });
    } else {
        if (show) {
            loadDefaultInformation();
        }
    }
}

function goToSearchUrl(searchString, searchType) {
    if (ajaxSearchSend) {
        ajaxSearchSend.abort();
    }
    let currentProductType = $("#currentProductTypeNewSearcher").val();
    let allProducts = $("#url-all-products").val();
    let agsch = $("#agsch").length ? $("#agsch").data('status') : false;
    let indexName = document.querySelector('#al-pi').value;
    /*if (agsch) {
        setCookie('_tv_agsch', 'true', 30);
    } else {
        setCookie('_tv_agsch', 'false', -30);
    }*/
    let urlProductType = currentProductType ? currentProductType : allProducts;
    let eventPayload = {
        event: 'searcher',
        search_term: searchString,
        method: searchType ? searchType : 'new-search'
    };
    let searchEventPayload = {
        event: 'search',
        search_term: searchString
    };
    if (agsch) {
        //trackEvent('Search', 'Searcher', 'Algolia');
        eventPayload['engine'] = 'algolia';
    } else {
        if (!indexName) {
            //trackEvent('Search', 'Searcher', '');
            eventPayload['engine'] = 'elastic';
        } else {
            //trackEvent('Search', 'Searcher', 'Algolia');
            eventPayload['engine'] = 'algolia';
        }
    }
    ga4TrackSimpleEvent(eventPayload);
    ga4TrackSimpleEvent(searchEventPayload);
    let url = addParamUrl('/'+urlProductType+'/search', 'q', searchString);
    if (agsch) url = addParamUrl(url, 'agsch', agsch);
    window.location.href = encodeURI(url);
}

function showLoaderSearch()
{
    $(".loader-search").show();
    $('.btn-remove-filters, .btn-show-results').prop("disabled", true);
    $(".container-results, .btn-show-sort, .btn-show-filters").addClass('loader-show');
}

function hideLoaderSearch()
{
    $(".loader-search").hide();
    $('.btn-remove-filters, .btn-show-results').prop("disabled", false);
    $(".container-results, .btn-show-sort, .btn-show-filters").removeClass('loader-show');
}

function addParamUrl(url, paramName, param)
{
    let prefix = url.indexOf('?') == -1 ? '?' : '&';

    return url+prefix+paramName+'='+param;
}

function loadDefaultInformation()
{
    updateLastSearches();
    $(".container-results").removeClass('loader-show');
    $(".container-products, .container-paginator, .container-filters").html('');
    $(".loader-search, .container-filters-buttons, .container-filters, .exit-filters, .container-filters-results, #panel-desktop-filters, #panel-desktop-sort").hide();

    $(".number-results-container").css('visibility', 'hidden');

    $("#container-last-searches, #container-trending-searches, #search-related-products, #search-related-products-mobile").show();

    if (ajaxSearchSend) {
        ajaxSearchSend.abort();
    }
}

function updateCookieLastSearches(text)
{
    let lastSearches = getCookie(lastSearchesCookie);
    if (!lastSearches || lastSearches === "") {
        lastSearches = [];
    } else {
        lastSearches = JSON.parse(lastSearches);
    }

    lastSearches = lastSearches.filter(function(item){
        return !eval('/^'+item+'/gi').test(text);
    });

    if (lastSearches.filter(function(item) {
            return eval('/^'+text+'/gi').test(item);
        }).length === 0) {
        if (lastSearches.length === 4) {
            lastSearches.splice(0, 1);
        }
        lastSearches.push(text);
    }

    setCookie(lastSearchesCookie, JSON.stringify(lastSearches), 10);
}

function resizeNewSearcherMobile()
{
    let screenHeight = (typeof window.outerHeight != 'undefined') ? Math.max(window.outerHeight, $(window).height()) : $(window).height();
    let searcherHeight = $("#header-mobile").height() + $(".container-filters-buttons").height() + $(".header-search-form-mobile").height();
    let resultsHeight = screenHeight - searcherHeight - 20;
    $("#header-mobile .container-results").height(resultsHeight);
}

function buildFilters()
{
    let filters = {
        productType: [$("input[name='product-type-filter']:checked").val()]
    };

    let isMobile = $("#header-mobile").is(":visible");
    let parentElement;
    if (isMobile) {
        parentElement = "#header-mobile";
    } else {
        parentElement = "#header-menu-middle";
    }

    $(parentElement+" input[name='category-filter']:checked").each(function() {
        let category = $(this).val();
        let type = $(this).data('type');
        let existsType = type in filters;
        if (!existsType) {
            filters[type] = [];
        }

        filters[type].push(category);
    });

    return filters;
}

let ajaxRequest;

function addProducts()
{
    let disableLoad = $('#disable-load-more-products').val() == 'true';
    if (typeof ajaxRequest == 'object' || disableLoad) {
        return false;
    }
    let type = $("#url-all-products").val();
    let filters = buildFilters();
    let text = searchInput.val();
    let url = addParamUrl('/'+type+'/search', 'q', text);
    let page = $('#current-page-new-searcher').val();

    ajaxRequest = $.post(
        '/ajax/product-list/add-products',
        {
            type: type,
            filters: filters,
            q: text,
            url: url,
            page: page,
            newSearcher: true,
            sort: $("input[name='searcher-sort']:checked").val()
        },
        function(data){
            trackEvent('Search', 'AddProducts', data.page.toString());
            $('#current-page-new-searcher').val(data.page);
            let newProducts = $(data.products);
            $(".container-products").append(newProducts);
            if (data.end) {
                $('#disable-load-more-products').val(true);
            }
            ajaxRequest = undefined;
        }
    ).fail(function() {
        ajaxRequest = undefined;
    });
}

function hideFilters()
{
    $('.container-products').slideDown('slow');
    $(".container-filters-buttons").show();
    $(".number-results-container").css('visibility', 'visible');
    $('.container-filters, .exit-filters, .container-filters-results, .container-sort').hide();
}

function loadAddToCartOptions(productId, productTitle, productPrice) {
    let addToCartOptionsContainer = $('#add-to-cart-options');
    showLoaderSearch();
    $.post(
        '/ajax/product-list/load-add-to-cart',
        {id: productId, title: productTitle, price: productPrice, newSearcher: true},
        function(data){
            $('body').css("overflow","hidden");
            addToCartOptionsContainer.html(data.options);
            $("#add-to-cart-size").data("ready", true);
            setCartFunctions($('.product-popup-options'), 'Search');
            $("#header-menu-middle .container-results-search").slideUp();
            removeSoftOverlay();
            updateView();
            setTimeout(function() {
                addUpperOverlay();
                hideLoaderSearch();
                addToCartOptionsContainer.find('.slide-up-slider').addClass('open');
            }, 300);
        }
    );
}

function hideMobileSearch()
{
    $('.header-search').removeClass('mobile-search-open');
    $('.header-search-form-mobile, .container-results-search').slideUp();
    $(".mobile-search-open").removeClass("full");
    removeSoftOverlay();
    trackEvent('Search', 'Mobile', 'open/close');
    $('.search-button').fadeIn();
}

function openSearchContainer(isDesktop) {
    /* Disabled Desktop Search Engine*/
    if(isDesktop === false){
        loadRelatedProducts($("#search-related-products"));
        $("#disable-load-more-products").val(false);
        if ($(".container-products .list-item").length == 0) {
            loadDefaultInformation();
        } else {
            //$("#search-related-products").hide();
        }
        $("#header-mobile .header-search").addClass('mobile-search-open');
        addSoftOverlay();

        /*if (!isDesktop) {
            $(".mobile-search-open").addClass("full");
        }*/

        $(".container-results-search").slideDown(function() {
            if (isDesktop) {
                $('.search-input.search-input-new:visible').focus();
            } else {
                $('#mobileSearchInput:visible').focus();
            }
        });

        let device = getDeviceType() == 'desktop' ? 'Desktop' : 'Mobile';
        trackEvent('Search', device, 'open/close');
    }
}

function updateLastSearches()
{
    let lastSearches = getCookie(lastSearchesCookie);

    if (lastSearches && lastSearches !== "") {
        lastSearches = JSON.parse(lastSearches);
        let html = "";

        lastSearches.forEach(function (search, index) {
            html += `
                <div class="row new-searcher-filter last-search-item" data-search="${search}">
                    <div class="col-xs-9">
                        <label class=""> ${search} </label>
                    </div>
                    <div class="col-xs-3">
                        <span class="glyphicon glyphicon-remove remove-search" data-index="${index}"></span>
                    </div>
                </div>
            `;
        })

        if (lastSearches.length > 0) {
            $("#btn-clear-last-searches").show();
        } else {
            $("#btn-clear-last-searches").hide();
        }

        $("#container-last-searches .content").html(html);
    } else {
        $("#btn-clear-last-searches").hide();
    }
}

function loadUserHeader()
{
    let userData = getCookie(userCookie);
    if (userData && userData !== '') {
        $.post(
            '/ajax/header/user-header-data',
            {},
            function(data){
                let colorClass = data.premium ? 'logged-premium-user' : 'logged-user';
                $("#hmtLogin .glyphicon").addClass(colorClass);
                $("#hmtLogin .hmt-btn-txt").html(data.name);
                $("#hmtLogin input[name='hmtUserUrl']").val(data.url);

                $(".login .glyphicon").addClass(colorClass);
                $('.tvi-user').addClass(colorClass);
                $(".login .txt").html(data.name);
                $(".login a").attr('href', data.url);
                if (localStorage) {
                    localStorage.setItem('logged_user_name', data.name);
                    localStorage.setItem('logged_user_premium', data.premium);
                }
            }
        );
    } else {
        if (localStorage) {
            localStorage.removeItem('logged_user_name');
            localStorage.removeItem('logged_user_premium');
        }
    }
}

let lastSearchesHTML = '';
function loadLastSearches(search, device)
{
    window.lastSearches = [];
    lastSearchesHTML = '';

    $.each(getLastSearches(), function(key, lastSearch) {
        renderLastSearch(lastSearch, search, true);
    });

    if (lastSearchesHTML) {
        showLastSearches(device);
        $(".new-header .searcher .search-suggestions").show();
    } else {
        hideLastSearches(device);
    }
}

function renderLastSearch(lastSearch, search, isSearch)
{
    lastSearch = lastSearch.toString();

    if (!window.lastSearches.some(addedLastSearch => addedLastSearch.localeCompare(lastSearch, undefined, {sensitivity: 'base'}) === 0)) {
        /*lastSearchesHTML += `
            <p class="search-suggestion ${isSearch ? "user-search" : ""}" data-search="${lastSearch}">
                <span class="remove-suggestion">x</span>
                <span class="text-suggestion"><span>${search}</span>${lastSearch.slice(search.length)}</span>
            </p>
        `;*/

        if ($('.new-header').length) {
            lastSearchesHTML += `
                <p class="search-suggestion ${isSearch ? "user-search" : ""}" data-search="${lastSearch}">
                    <span class="remove-suggestion tvi tvi-times"></span>
                    <span class="text-suggestion">${lastSearch}</span>
                </p>
            `;
        } else {
            lastSearchesHTML += `
                <p class="search-suggestion ${isSearch ? "user-search" : ""}" data-search="${lastSearch}">
                    <span class="remove-suggestion">x</span>
                    <span class="text-suggestion">${lastSearch}</span>
                </p>
            `;
        }
        window.lastSearches.push(lastSearch);
    }
}

function showLastSearches(device)
{
    let lastSearchesContainer = $('#new-design-search .search-suggestions .last-searches, #mobile-searcher .search-suggestions .last-searches');
    if (device === 'desktop') lastSearchesContainer = $('#hmtSearch .search-suggestions .last-searches');
    if ($("#header-menu-mobile .mobile-menu-left").hasClass('open')) lastSearchesContainer = $("#header-menu-mobile .mobile-menu-left .searcher .search-suggestions .last-searches");

    lastSearchesContainer.html(lastSearchesHTML).show();
}

function hideLastSearches(device)
{
    let lastSearchesContainer = $('#new-design-search .search-suggestions .last-searches, #mobile-searcher .search-suggestions .last-searches');
    if (device === 'desktop') lastSearchesContainer = $('#hmtSearch .search-suggestions .last-searches');
    if ($("#header-menu-mobile .mobile-menu-left").hasClass('open')) lastSearchesContainer = $("#header-menu-mobile .mobile-menu-left .searcher .search-suggestions .last-searches");

    lastSearchesContainer.hide().html('');
}

function clearSuggestions()
{
    $('.search-suggestions .tags').hide().html('');
}

function loadLastSearch()
{
    let search = getUrlParameter('lstkw');
    if (search) $("#header-menu .search-input, #header-mobile .search-input").val(search);
    $('.search-input').each(function() {
        if ($(this).val()) {
            $(this).siblings('.remove-search-text').show();
        }
    });
}

function getUrlParameter(sParam) {
    let sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }

    return null;
}

function initSearchers(productType)
{
    initSearcher(document.querySelector('.desktopSearch'), getCurrentLanguage(), productType, maxSuggestions, true);
    initSearcher(document.querySelector('#new-design-search'),getCurrentLanguage(), productType, maxSuggestions, true);
    initSearcher(document.querySelector('#header-menu-mobile .mobile-menu-left .searcher'),getCurrentLanguage(), productType, maxSuggestions, true);
}

function resizeLeftMenu()
{
    let screenHeight = (typeof window.outerHeight != 'undefined') ? Math.max(window.outerHeight, $(window).height()) : $(window).height();
    let topHeight =  $('#header-menu-mobile .mobile-menu-left .top').height();
    let bottomHeight = $('#header-menu-mobile .mobile-menu-left .bottom').height();
    if (isIOS) screenHeight = window.innerHeight;
    $('#header-menu-mobile .mobile-menu-left .middle').css({"maxHeight": (screenHeight - topHeight - bottomHeight) - 10 +'px'});
}

function resizeCategoriesMobile()
{
    let screenHeight = (typeof window.outerHeight != 'undefined') ? Math.max(window.outerHeight, $(window).height()) : $(window).height();
    let headerHeight = $("#header-mobile").height();
    let height = ((screenHeight - headerHeight) / 1.25) + 40;
    $('#header-mobile .hmb-links-container').css({"maxHeight": height+"px"});
}

function closeLeftMenu()
{
    $('#header-menu').removeClass('header-menu-open');
    $('#header-menu-close').delay(400).hide();
    $('#mobileMenu').delay(400).show();
    removeOverlay();
    $("#header-menu-mobile .mobile-menu-left").removeClass('open');
    trackEvent('Menu', 'Opened', 'Close');
}

let ajaxGetSuggestions = null;

function renderElasticSuggestions(input)
{
    let search = input.val();
    if (ajaxGetSuggestions) ajaxGetSuggestions.abort();
    let totalSuggestionsRendered = window.lastSearches.length;
    if (totalSuggestionsRendered >= maxSuggestions) return;
    let form = input.closest('.new-searcher-desktop, .new-header .searcher');
    let productTypeSelected = form.find('.dropdown-option.selected').data('originalName');

    let specialCharacters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (specialCharacters.test(search) || search.trim().length === 0) {
        form.find(".search-suggestions").hide();
        return;
    }

    ajaxGetSuggestions = $.post(
        '/ajax/website/search/get-suggestions',
        {
            search: search,
            productType: isMobile() || productTypeSelected === 'All products' ? '' : productTypeSelected
        },
        function(data){
            form.find('.search-suggestions .tags').html('');
            let suggestions = data.tags;
            let addedSuggestions = getLastSearches();
            let countAddedSuggestions = addedSuggestions.length;
            let html = '';
            suggestions.forEach(function(suggestion) {
                if (countAddedSuggestions > maxSuggestions /*|| addedSuggestions.includes(suggestion)*/) return;
                html += `
                <p class="search-suggestion" data-search="${suggestion}">
                    <span class="remove-suggestion" style="visibility: hidden;">x</span>
                    <span class="text-suggestion"><span>${search}</span>${suggestion.slice(search.length)}</span>
                </p>`;
                countAddedSuggestions++;
            });

            if (html !== '') {
                form.find('.search-suggestions .tags').html(html);
                form.find('.search-suggestions .tags').show();
                form.find(".search-suggestions").show();
            } else if (form.find(".search-suggestions .last-searches").html() === "" && $('.new-header').length) {
                form.find(".search-suggestions").hide();
            }

            ajaxGetSuggestions = null;
        }
    );
}