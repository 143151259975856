import {addOverlay, removeOverlay} from "./overlay";

$(document).ready(function() {
    $(document).on('click', '#menu-right-full-close', function () {
        hideMenuRightFull();
    });
    $(document).on('click', '#menu-left-full-close', function () {
        hideMenuLeftFull();
    });

    $(document).on('click', '#rightMenuFull', function () {
        hideMenuRightFull();
        if ($('#leftMenuCheckout').length) {
            $('#leftMenuCheckout span').click();
        } else {
            $('#mobileMenu span').click();
        }
    });
});

export function hideMenuRight() {
    $('#menu-right-container').removeClass('menu-right-open');
    $('#menu-right-close').hide();
    removeOverlay();
}

export function openMenuRight() {
    addOverlay();
    $('#menu-right-container').addClass('menu-right-open');
    $('#menu-right-close').delay(400).show(0);
}

/////////// MENU RIGHT MENU
export function openMenuRightFull() {
    if (!$('#menu-right-full-container').hasClass('open')) {
        addOverlay();
        $('#menu-right-full-container').addClass('open');
    }
}

export function hideMenuRightFull() {
    $('#menu-right-full-container').removeClass('open');
    removeOverlay();
}

export function showRightMenu(title, body, callback) {
    title = title || '';
    $('#menu-right-full-container .menu-right-full-title .title').html(title);
    $('#menu-right-full-container .body').html(body).promise().done(function () {
        //setShoppingCartListHeight();
        $('img.lazy-load-img').unveil();
        openMenuRightFull();
        if (callback) callback();
    });
}
/////////// MENU LEFT MENU
export function openMenuLeftFull() {
    addOverlay();
    $('#menu-left-full-container').addClass('open');
}

export function hideMenuLeftFull() {
    $('#menu-left-full-container').removeClass('open');
    removeOverlay();
}

export function showLeftMenu(title, body) {
    title = title || '';
    body = body || '';
    $('#menu-left-full-container .menu-left-full-title .title').html(title);
    if (body.length) {
        $('#menu-left-full-container .body').html(body).promise().done(function () {
            //setShoppingCartListHeight();
            $('img.lazy-load-img').unveil();
            openMenuLeftFull();
        });
    } else {
        openMenuLeftFull();
    }
}